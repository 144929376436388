<template lang="">
    <div v-if="loading" class="loading-overlay">
        <div class="spinner"></div>
        Loading...
    </div>
    <div class="container-fluid py-5">
        <div class="box top-box mb-3 p-4 rounded-3 bg-white d-flex align-items-center shadow">
            <h4 class="font-weight-bold">WMS Store Master</h4>
        </div>
        <!-- Button group -->
        <div class="d-flex justify-content-between mb-0">
            <!-- 기존 요소들 -->
            <div class="d-flex align-items-center">
                <input type="text" ref="inputText" v-model="inputText" placeholder="ENTER A BARCODE" class="form-control d-inline-block custom-input w-auto ml-3" @keyup.enter="fetchData">
                <button class="btn btn-primary ml-3 custom-button w-40" @click="fetchData">CHECK</button>
                <v-chip class="ma-2" color="primary" label>
                    <v-icon icon="mdi-truck-cargo-container" start></v-icon>
                    Total Qty: {{this.totalQty}}
                </v-chip>

            </div>
            
            <!-- 오른쪽으로 이동된 SAVE 버튼 -->
            <button class="btn btn-secondary custom-button" @click="saveData">SAVE</button>
        </div>
        
            <div class="justify-content-between align-items-center mb-4 bg-white shadow rounded-3 p-4 align-item-center">
                <h4 class="mb-3">STORE LIST</h4>

                <input type="text" v-model="searchQuery" placeholder="Part Search..." style="margin-bottom:10px" />
                <table class="table table-bordered table-hover custom-table">
                    <thead class="text-center">
                        <tr>
                            <th scope="col">Index</th>
                            <th scope="col">Container No</th>
                            <th scope="col">Part Number</th>
                            <th scope="col">Part Name</th>
                            <th scope="col">Box Qty</th>
                            <th scope="col">LOT NO</th>
                            <th scope="col">Supplier Code</th>
                            <th scope="col">Invoice Number</th>            
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr v-for="(row, index) in displayedRows" :key="index">
                            <td>{{ index + 1 + startIndex }}</td>
                            <td>{{ row.CONT_NO }}</td>
                            <td v-if="isNumericChar(row.ITMNO.charAt(0))">{{ row.ITMNO.substring(0,5) + '-' + row.ITMNO.substring(5) }}</td>
                            <td v-if="!isNumericChar(row.ITMNO.charAt(0))">{{ row.ITMNO.substring(0,6) + '-' + row.ITMNO.substring(6) }}</td>
                            <td>{{ row.ITM_NM }}</td>
                            <td>{{ row.BOX2_QTY }}</td>
                            <td>{{ row.BOX_LOT }}</td>
                            <td>{{ row.ST_CUST_CD }}</td>
                            <td>{{ row.INVO_NO }}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- 페이지 컨트롤 -->
                <div class="d-flex justify-content-center mt-4">
                    <ul class="pagination">
                        <!-- 이전 버튼 -->
                        <li class="page-item" :class="{ 'disabled': currentPage <= 1 }">
                            <button class="page-link" @click="prevPage" :disabled="currentPage <= 1">Prev</button>
                        </li>

                        <!-- 현재 페이지와 총 페이지 수 표시 -->
                        <li class="page-item disabled">
                            <span class="page-link">{{ currentPage }} / {{ totalPage }}</span>
                        </li>

                        <!-- 다음 버튼 -->
                        <li class="page-item" :class="{ 'disabled': currentPage >= totalPage }">
                            <button class="page-link" @click="nextPage" :disabled="currentPage >= totalPage">Next</button>
                        </li>
                    </ul>
                </div>


            
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            currentPage: 1, // 현재 페이지를 나타냄
            itemsPerPage: 20, // 페이지 당 항목 수
            loading: false,
            searchQuery: '',
            inputText: '',
            ordNo: '',
            selectedValue: null, // MGA 또는 MAL 값을 저장하기 위한 데이터 속성
            tableRows: [],
            isFill: false,
            totalQty: 0,
        };
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$refs.inputText) {
                this.$refs.inputText.focus();
            }
        });
    },
    computed: {
        // 현재 페이지의 시작 인덱스
        startIndex() {
            return (this.currentPage - 1) * this.itemsPerPage;
        },
        // 현재 페이지의 끝 인덱스
        endIndex() {
            return this.startIndex + this.itemsPerPage;
        },
        // 현재 페이지에 표시할 항목
        displayedRows() {
            let filteredList = this.tableRows;
            return filteredList.slice(this.startIndex, this.endIndex);
        },
        // 총 페이지 수
        totalPage() {
            return Math.ceil(this.tableRows.length / this.itemsPerPage);
        }
    },
    methods: {
        setSelection(value) {
            if (this.selectedValue == value) {
                this.selectedValue = null;
            } else {
                this.selectedValue = value;
            }

        },
        // 다음 페이지로 이동
        nextPage() {
            if (this.currentPage < this.totalPage) {
                this.currentPage++;
            }
        },
        // 이전 페이지로 이동
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        // 입고처리할 데이터 가져오는 메서드
        async fetchData() {
            this.loading = true;
            try {
                if (this.inputText.trim() != '') {
                    const response = await axios.get('/api/getStoreParts', {
                        params: {
                            query: this.inputText
                        }
                    });
                    if (Object.values(response.data.partsList)[0].STATUS != 2) {
                        this.tableRows = Object.values(response.data.partsList);
                        this.currentPage = 1;
                        for (let item of this.tableRows) {
                            this.totalQty += item.BOX2_QTY;
                        }
                    } else {
                        alert("This packing list is already saved!");
                    }
                } else {
                    alert("Scan Packing List");
                }


            } catch (err) {
                console.error('Data fetch error: ', err);
            }
            this.loading = false;
            this.ordNo = this.inputText;
            this.inputText = '';
        },

        // 입고 데이터 저장 메서드
        async saveData() {
            this.loading = true;
            try {
                if (this.ordNo != '') {
                    const response = await axios.post('/api/saveStoreParts', {
                        ordNo: this.ordNo
                    });
                    if (response.data.success) {
                        alert("성공적으로 업로드 되었습니다.");
                    } else {
                        alert("업로드에 실패했습니다.");
                    }
                } else {
                    alert("Scan Packing List");
                }

            } catch (err) {
                console.error('Data save error: ', err);
            }
            this.tableRows = [];
            this.loading = false;
        },
        goToPage(pageNum) {
            if (pageNum >= 1 && pageNum <= this.totalPages) {
                this.currentPage = pageNum;
            }
        },
        isNumericChar(char) {
            return !isNaN(parseFloat(char)) && isFinite(char);
        }
    }
}
</script>
<style scoped>
.selected {
    background-color: #0dcaf0;
    color: white;
    border-color: #0dcaf0;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid black;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.min-height {
    min-height: 647px;
}

.btn-outline-info {
    margin-left: 5px;
}

.sh-logo {
    transform: translateY(-2px);
    margin-right: 10px;
}

.custom-table {
    min-height: 450px;
}

.custom-input {
    border: 1px solid;
    background-color: white;
    padding-left: 5px;
}

.custom-button {
    margin-top: 15px;
}

.custom-input:focus {
    background-color: white !important;
    border: 2px solid;
    padding-left: 5px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
