<template>
  <div v-if="loading" class="loading-overlay">
        <div class="spinner"></div>
        Loading...
    </div>
  <sidenav v-if="showSidenav && !isLoginPage" :custom_class="color" class="fixed-start custom-font"
    :username="username" />

  <main class="main-content position-relative h-100 ps overflow-x-hidden"
    :class="`${isLoginPage ? 'login-page' : ''} ${isDisplay ? '' : 'isDisplay'}`">
    <!-- nav -->
    <navbar v-if="showNavbar" :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''" :min-nav="navbarMinimize" />
    <router-view :username="username"></router-view>

    <app-footer v-show="showFooter && !isLoginPage" />

  </main>
</template>
<script>
import router from './router/index';
import Sidenav from './components/Sidenav';
import Navbar from "@/components/Navbars/Navbar.vue";
import AppFooter from "@/components/Footer.vue";
import { mapState } from "vuex";
// import axios from 'axios';

// import axios from 'axios';
export default {
  name: 'App',
  
  components: {
    Sidenav,
    AppFooter,
    Navbar
  },
  mounted() {
    this.loading = true;
    
    if (!this.isLoginPage) {
      this.$store.dispatch('checkSessionStatus');
      
    }
    this.loading = false;
  },
  computed: {
    ...mapState([
      "showSidenav",
      "showFooter",
      "isAbsolute",
      "isNavFixed",
      "username"
    ]),
    isLoginPage() {
      return this.$route.meta.isLoginPage;
    },


  },
  beforeMount() {
    this.loading = true;
    this.getUsername();
    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
    this.loading = false;
    
  },
  router,
  data() {
    return {
      isDisplay: true,
      loading: false,
    };
  },
  methods: {
    // 메서드 업데이트
    // getUsername() {
    //   // 비동기 로직은 바로 반환되지 않으므로, 결과를 state에 저장해야 합니다.

    //   if (!this.$route.meta.isLoginPage) {
    //     axios.get('http://localhost:3000/get-username')
    //       .then((response) => {
    //         console.log("hello");
    //         this.username = response.data.username; // 서버로부터 받은 username으로 업데이트
    //       })
    //       .catch((error) => {
    //         console.error('Error fetching username:', error);
    //       });
    //   }
    // },

    async getUsername() {
      this.loading = true;
      try {
        await this.$store.dispatch('checkSessionStatus');
        // `checkSessionStatus` 액션은 Vuex 스토어의 `username` 상태를 업데이트합니다.
        // 여기서는 별도의 처리가 필요하지 않습니다.
        
      } catch (error) {
        console.error('Error fetching username:', error);
      }
      this.loading = false;
    },
    updateUser(username) {
      this.$store.commit('setUsername', username);

    },
  }

};
</script>

<style scoped>
body main , body .sidenav, body .sidenav .sidenav-list, body main div{
  font-family: 'Noto Sans KR', "Noto Sans", sans-serif !important;
}
h3 {
  font-family: 'Noto Sans KR', "Noto Sans", sans-serif !important;
}
#app {
  font-family: 'Noto Sans KR', "Noto Sans", sans-serif !important;
}
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid black;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.maximize-content {
    margin-left: 0 !important;
    background: black;
}



.sidenav {
  z-index: 1000 !important;
}
</style>