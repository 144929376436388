import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import store from "./store";
import './assets/css/global.css';
import vuetify from './plugins/vuetify'
import MaterialDashboard from "./material-dashboard";
const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(MaterialDashboard);
appInstance.use(vuetify);
appInstance.mount("#app");