<template lang="">
    <div v-if="loading" class="loading-overlay">
        <div class="spinner"></div>
        Loading...
    </div>
    <div class="container-fluid py-5">
        <div class="box top-box mb-3 p-4 rounded-3 bg-white d-flex align-items-center shadow">
            <h4 class="font-weight-bold">Part Dashboard</h4>
        </div>
        <!-- Button group -->
        <!-- <div class="d-flex">
            
            <div class="mr-3 custom-date-box shadow"> -->
                <!--@update:model-value="fetchDataByDate"-->
                <!-- <vue-datepicker v-model="date" range placeholder="Start Typing ..."
                    :text-input="textInputOptions" :is-24="false" :enable-time-picker="false"></vue-datepicker>
                
            </div>
            <div class="d-flex mr-3">
                <p class="black font-weight-bold mt-3 mr-3">STATUS</p>
                <select class="custom-invo-input form-select mt-2 shadow" v-model="selectedStatus" title="Status"> -->
                    <!-- 출고지 옵션을 동적으로 렌더링 -->
                    <!-- <option  v-for="(status, index) in statusOptions" :key="index" :value="status.value">{{ status.name }}</option>
                </select>
            </div>
            <v-btn prepend-icon="mdi-magnify" class="mt-2 mr-3" @click="fetchDataByFilter">Inquiry</v-btn>
            
        </div> -->
        <v-card class="mt-1">
        
            <v-tabs v-model="tab" color="deep-purple-accent-4">
                <v-tab value="1">Part Table</v-tab>
                <!-- <v-tab value="2">Shipping</v-tab> -->
                <!-- <v-tab value="9">Completed</v-tab> -->
            </v-tabs>
            
    
            <v-card-text>
                <v-window v-model="tab">
                    <v-window-item value="1">
                        <v-text-field
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            single-line
                            variant="outlined"
                            hide-details
                        >   
                        </v-text-field>
                        <v-data-table
                            :custom-filter="filterOnlyCapsText"
                            :headers="headers"
                            :items="items"
                            :search="search"
                            item-value="name"
                            >
                            <!-- <template v-slot:top>
                                
                            </template> -->
<template #[`item`]="{ item }">
                                <tr class="data-table-row">
                                    <td class="text-start">{{ items.indexOf(item) + 1 }}</td>
                                    <td class="font-weight-bold text-start">{{ item.ORD_NO }}</td>
                                    <td class="text-start">{{ item.CONT_NO }}</td>
                                    <td v-if="isNumericChar(item.ITMNO.charAt(0))" class="custom-class text-center font-weight-black">{{ item.ITMNO.substring(0,5) + '-' + item.ITMNO.substring(5) }}</td>
                                    <td v-if="!isNumericChar(item.ITMNO.charAt(0))" class="custom-class text-center font-weight-black">{{ item.ITMNO.substring(0,6) + '-' + item.ITMNO.substring(6) }}</td>
                                    <td class="text-end">{{ item.ITM_NM }}</td>
                                    <td class="text-end">{{ item.BOX2_QTY }}</td>
                                    <td class="text-end">{{ item.BOX_QR }}</td>
                                    <td class="text-end">{{ item.CO_GB }}</td>
                                    <td class="text-end">
                                    <v-chip
                                        v-if="item.STATUS==='2'"
                                        color="green"
                                        class="text-uppercase"
                                        label
                                        size="small"
                                    >
                                        Received
                                    </v-chip>
                                    <v-chip
                                        v-if="item.STATUS==='6'"
                                        color="orange"
                                        class="text-uppercase"
                                        label
                                        size="small"
                                    >
                                        Shipping
                                    </v-chip>
                                    
                                    </td>
                                </tr>
                            </template>

</v-data-table>
</v-window-item>

</v-window>
</v-card-text>
</v-card>
</div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            tab: null,
            search: '',
            headers: [
                {
                    title: 'No',
                    align: 'start',
                    key: ''
                },
                {
                    title: 'Ord No',
                    align: 'start',
                    key: 'ORD_NO'
                },
                {
                    title: 'Cont No',
                    align: 'start',
                    key: 'CONT_NO'
                },
                {
                    title: 'Item No',
                    align: 'start',
                    key: 'ITMNO'
                },
                {
                    title: 'Item Name',
                    align: 'end',
                    key: 'ITM_NM'
                },
                {
                    title: 'Qty',
                    align: 'end',
                    key: 'BOX2_QTY'
                },
                {
                    title: 'Box Code',
                    align: 'end',
                    key: 'BOX_QR'
                },
                {
                    title: 'Supplier',
                    align: 'end',
                    key: 'CO_GB'
                },
                {
                    title: 'Status',
                    align: 'end',
                    key: 'STATUS'
                },
            ],
            loading: false,
            items: [],

        };
    },
    mounted() {
        this.fetchData();
    },
    computed: {

    },
    methods: {

        // 출고처리할 데이터 가져오는 메서드
        async fetchData() {
            this.loading = true;
            try {
                const response = await axios.get('/api/getOffsetParts');
                if (response.data.err) {
                    console.log(response.data.err);
                } else if (response.data.message) {
                    console.log(response.data.message);
                } else {
                    this.items = response.data;
                }

            } catch (err) {
                console.error('Data fetch error: ', err);
            }
            this.loading = false;

        },
        isNumericChar(char) {
            return !isNaN(parseFloat(char)) && isFinite(char);
        }

    }
}
</script>
<style scoped>
.data-table-row td {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;

}

.data-table-row td {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;

}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 9999;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid black;
    width: 40px;
    height: 40px;
    z-index: 9999;
    animation: spin 1s linear infinite;
}

.data-table-row:hover {
    background-color: #f5f5f5;
    /* 마우스 오버 시 배경색 변경 */
    cursor: pointer;
}
</style>