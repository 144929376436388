import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import axios from 'axios';

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    color: "success",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    username: null,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",

    bootstrap
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    setIsPinned(state, payload) {
      state.isPinned = payload;
    },
    color(state, payload) {
      state.color = payload;
    },
    navbarMinimize(state) {
      const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];

      if (sidenavShow.classList.contains("g-sidenav-pinned")) {
        sidenavShow.classList.remove("g-sidenav-pinned");
        sidenavShow.classList.add("g-sidenav-hidden");
        state.isPinned = true;
      } else {
        sidenavShow.classList.remove("g-sidenav-hidden");
        sidenavShow.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    setLoggedIn(state, status) {
      state.loggedIn = status;
    },
    setUsername(state, username) {
      state.username = username; // 사용자 이름 업데이트 뮤테이션
    }
  },
  actions: {
    setColor({ commit }, payload) {
      commit("color", payload);
    },
    async checkSessionStatus({ commit }) {
      try {
        const response = await axios.get('/api/session-status');
        commit('setLoggedIn', response.data.loggedIn);
        
        if (response.data.loggedIn) {
          commit('setUsername', response.data.username); // 세션 상태에 따라 사용자 이름도 설정
        } else {
          console.log("not loggedin")
        }
      } catch (error) {
        console.error('Error checking session status:', error);
      }
    }
  },
  getters: {}
});
