<template>
  <li class="nav-item active">
    <router-link class="nav-link" :to="to" @click="handleClick">
      <!--sidenav-mini-icon-->
      <i class="material-symbols-outlined text-white"> {{ miniIcon }} </i>
      <span class="sidenav-normal me-3 ms-3 ps-1 text-white text-white">
        {{ text }}
      </span>
    </router-link>
  </li>
</template>
<script>
export default {
  name: "SidenavItem",
  props: {
    to: {
      type: [Object, String],
      required: true
    },
    miniIcon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  methods: {
    handleClick() {
      console.log("clicked")
      const currentPath = this.$route.path;
      const targetPath = typeof this.to === 'string' ? this.to : this.to.path;
      if (currentPath === targetPath) {
        // 같은 URL로 이동할 때 페이지 새로고침
        window.location.reload();
      }
    },
  },

};
</script>
