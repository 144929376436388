<template>
    <div v-if="loading" class="loading-overlay">
        <div class="spinner"></div>
        Loading...
    </div>
    <div class="modal show d-block" id="productModal" tabindex="-1" aria-labelledby="productModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="d-flex modal-header justify-space-between">
                    <div class="custom-font-title"><strong>입출고 상세 내역</strong></div>
                    <v-btn icon="$close" variant="text" @click="closeScreen"></v-btn>

                </div>
                <v-container>
                    <v-row>
                        <!-- 품번 -->
                        <v-col cols="12" sm="6" md="1">
                            <v-card class="pa-3 d-flex justify-center align-center text-center"
                                style="background-color: lightblue;">
                                <strong>품번</strong>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="2">
                            <v-text-field v-model="itmno" :rules="rules" class="custom-text-color" style="height:50px;" @keyup.enter="fetchInItemQty"></v-text-field>
                        </v-col>

                        <!-- 품명 -->
                        <v-col cols="12" sm="6" md="1">
                            <v-card class="pa-3 d-flex justify-center align-center text-center"
                                style="background-color: lightblue;">
                                <strong>품명</strong>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-card class="pa-3 d-flex justify-center align-center text-center"
                                style="border: 1px solid black;">
                                <strong>{{ item.ITMNM }}</strong>
                            </v-card>
                        </v-col>
                        <!--조회 날짜-->
                        <v-col cols="12" sm="6" md="1">
                            <v-card class="pa-3 d-flex justify-center align-center text-center"
                                style="background-color: lightblue;">
                                <strong>조회기간</strong>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" class="mt-2">
                            <vue-datepicker v-model="date" range placeholder="Start Typing ..."
                                :text-input="textInputOptions" :is-24="false"
                                :enable-time-picker="false"></vue-datepicker>

                        </v-col>
                        <v-col cols="12" sm="6" md="1">
                            <v-btn prepend-icon="mdi-magnify" @click="fetchInItemQty" style="background-color: lightblue; height:50px;"><strong>Inquiry</strong></v-btn>
                        </v-col>

                    </v-row>
                    <v-row>
                        <!-- 기초재고 -->
                        <v-col cols="12" sm="6" md="1">
                            <v-card class="pa-3 d-flex justify-center align-center text-center" color="yellow">
                                <strong>기초재고</strong>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="1">
                            <v-card class="pa-3 d-flex justify-center align-center text-center"
                                style="border: 1px solid black;">
                                <strong>{{ item.TOTAL_BEGINNING_QTY.toLocaleString() }}</strong>
                            </v-card>
                        </v-col>

                        <!-- 입고 -->
                        <v-col cols="12" sm="6" md="1">
                            <v-card class="pa-3 d-flex justify-center align-center text-center" color="blue">
                                <strong>입고</strong>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="1">
                            <v-card class="pa-3 d-flex justify-center align-center text-center"
                                style="border: 1px solid black;">
                                <strong>{{ item.TOTAL_IN_QTY.toLocaleString() }}</strong>
                            </v-card>
                        </v-col>

                        <!-- 출고 -->
                        <v-col cols="12" sm="6" md="1">
                            <v-card class="pa-3 d-flex justify-center align-center text-center" color="red">
                                <strong>출고</strong>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="1">
                            <v-card class="pa-3 d-flex justify-center align-center text-center"
                                style="border: 1px solid black;">
                                <strong>{{ item.TOTAL_OUT_QTY.toLocaleString() }}</strong>
                            </v-card>
                        </v-col>

                        <!-- 기말재고 -->
                        <v-col cols="12" sm="6" md="1">
                            <v-card class="pa-3 d-flex justify-center align-center text-center" color="purple">
                                <strong>기말재고</strong>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="1">
                            <v-card class="pa-3 d-flex justify-center align-center text-center"
                                style="border: 1px solid black;">
                                <strong>{{ item.TOTAL_END_QTY.toLocaleString() }}</strong>
                            </v-card>
                        </v-col>

                        <!-- 현재고 -->
                        <v-col cols="12" sm="6" md="1">
                            <v-card class="pa-3 d-flex justify-center align-center text-center" color="green">
                                <strong>현재고</strong>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="1">
                            <v-card class="pa-3 d-flex justify-center align-center text-center"
                                style="border: 1px solid black;">
                                <strong>{{ item.TOTAL_CURRENT_QTY.toLocaleString() }}</strong>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>

                <div class="modal-body d-flex">
                    <v-card class="mt-1 col-md-6" style="margin-left:-4px !important">
                        <v-card-title>입고</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-window v-model="tab">

                                <v-data-table :custom-filter="filterOnlyCapsText" :headers="inHeaders"
                                    :items="incomingItems" item-value="name" height="500">
                                    <template #[`headers`]="{ columns }">
                                        <tr class="border grey-lighten-1">
                                            <template v-for="column in columns" :key="column.key">
                                                <td class="border text-center">
                                                    <span class="mr-2">{{ column.title }}</span>
                                                </td>
                                            </template>
                                        </tr>
                                    </template>
                                    <template #[`item`]="{ item }">
                                        <tr v-for="(totalQty, index) in item.TOTAL_QTY" :key="index"
                                            class="data-table-row">
                                            <td v-if="index === 0" class="align-center text-center"
                                                :rowspan="item.TOTAL_QTY.length">
                                                {{ item.UNLOAD_YMD.substring(0, 4) + "/" + item.UNLOAD_YMD.substring(4,
        6) + "/" + item.UNLOAD_YMD.substring(6) }}
                                            </td>
                                            <td class="align-center text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-end">{{ totalQty.toLocaleString() }}</td>
                                            <td class="align-center text-center">{{ custCd[item.ST_CUST_CD[index]] }}
                                            </td>
                                            <td class="align-center text-center">{{ item.CONT_NO[index] }}</td>
                                            <td class="align-center text-center">{{ item.BOX_LOT[index] }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-window>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-1 col-md-6 ml-2">
                        <v-card-title>출고</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-window v-model="tab">

                                <v-data-table :custom-filter="filterOnlyCapsText" :headers="outHeaders"
                                    :items="outgoingItems" item-value="name" height="500">
                                    <template #[`headers`]="{ columns }">
                                        <tr class="border grey-lighten-1">
                                            <template v-for="column in columns" :key="column.key">
                                                <td class="border text-center">
                                                    <span class="mr-2">{{ column.title }}</span>
                                                </td>
                                            </template>
                                        </tr>
                                    </template>
                                    <template #[`item`]="{ item }">
                                        <tr v-for="(totalQty, index) in item.TOTAL_QTY" :key="index"
                                            class="data-table-row">
                                            <td v-if="index === 0" class="align-center text-center"
                                                :rowspan="item.TOTAL_QTY.length">
                                                {{ item.ESTIMATE_DATE.substring(0, 4) + "/" +
        item.ESTIMATE_DATE.substring(4, 6) + "/" +
        item.ESTIMATE_DATE.substring(6, 8) }}
                                            </td>
                                            <td v-if="index === 0" class="align-center text-center"
                                                :rowspan="item.TOTAL_QTY.length">
                                                {{ item.ESTIMATE_DATE.substring(8, 13) }}
                                            </td>
                                            <td class="align-center text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-end">{{ totalQty.toLocaleString() }}</td>
                                            <td class="align-center text-center">{{ custCd[item.DEST[index]] }}</td>
                                            <td class="align-center text-center">{{ item.CONT_NO[index] }}</td>
                                            <td class="align-center text-center">{{ item.BOX_LOT[index] }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-window>
                        </v-card-text>
                    </v-card>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import VueDatepicker from '@vuepic/vue-datepicker';
export default {
    props: ['itm', 'incomingDetail', 'outgoingDetail'],
    components: {
        VueDatepicker,
    },
    data() {
        return {
            loading: false,
            search: '',
            tab: null,
            inHeaders: [
                {
                    title: '입고 날짜',
                    align: 'center',
                    sortable: false,
                    key: 'UNLOAD_YMD',
                    class: 'custom-th-border'
                },
                {
                    title: '순번',
                    align: 'center',
                    sortable: false,
                    key: 'index',
                    class: 'custom-th-border'
                },
                {
                    title: '총 수량',
                    align: 'end',
                    sortable: false,
                    key: 'TOTAL_QTY',
                    class: 'custom-th-border'
                },
                {
                    title: '공급처',
                    align: 'center',
                    sortable: false,
                    key: 'ST_CUST_CD',
                    class: 'custom-th-border'
                },
                {
                    title: '컨테이너 No',
                    align: 'center',
                    sortable: false,
                    key: 'CONT_NO',
                    class: 'custom-th-border'
                },
                {
                    title: '로트번호',
                    align: 'center',
                    sortable: false,
                    key: 'BOX_LOT',
                    class: 'custom-th-border'
                },
            ],
            outHeaders: [
                {
                    title: '출고 날짜',
                    align: 'center',
                    sortable: false,
                    key: 'ESTIMATE_DATE',
                    class: 'custom-th-border'
                },
                {
                    title: '시간',
                    align: 'center',
                    sortable: false,
                    key: 'ESTIMATE_DATE',
                    class: 'custom-th-border'
                },
                {
                    title: '순번',
                    align: 'center',
                    sortable: false,
                    key: 'index',
                    class: 'custom-th-border'
                },
                {
                    title: '총 수량',
                    align: 'end',
                    sortable: false,
                    key: 'TOTAL_QTY',
                    class: 'custom-th-border'
                },
                {
                    title: '거래처',
                    align: 'center',
                    sortable: false,
                    key: 'DEST',
                    class: 'custom-th-border'
                },
                {
                    title: '컨테이너 No',
                    align: 'center',
                    sortable: false,
                    key: 'CONT_NO',
                    class: 'custom-th-border'
                },
                {
                    title: '로트번호',
                    align: 'center',
                    sortable: false,
                    key: 'BOX_LOT',
                    class: 'custom-th-border'
                },
            ],
            custCd: {
                'P0300': 'CNJ',
                'P0200': 'Shinhwa',
                'P0806': 'LOGIS ALL',
                'P0807': 'Leehwa',
                'P1100': 'Korea Movenex',
                'P1900': 'Seohan ENP',
                'P1901': 'Seohan Innobility',
                'P1903': 'SAU Plant 1',
                'P0250': 'SAU Plant 2'
            },
            addedProducts: [],
            incomingItems: [],
            outgoingItems: [],
            item: {},
            items: [],
            itmno: '',
            date: null,
        };
    },
    mounted() {
        this.loading = true;
        this.incomingItems = Object.values(this.incomingDetail);
        this.outgoingItems = Object.values(this.outgoingDetail);
        this.item = this.itm;
        this.itmno = this.item.ITMNO;
        this.date = [this.item.startDay, this.item.endDay];
        console.log(this.outgoingItems);
        this.loading = false;
    },
    computed: {
        // filteredProducts() {
        //     // for (let item of this.products) {
        //     //     this.items.push({
        //     //         'CONT_NO': item.CONT_NO
        //     //     })
        //     // }
        //     return this.products;
        // }
    },
    methods: {
        async fetchInItemQty() {
            this.loading = true;
            try {
                if (this.date != null) {
                    const startDay = this.date[0].getFullYear() + String(this.date[0].getMonth() + 1).padStart(2, '0')
                        + String(this.date[0].getDate()).padStart(2, '0');
                    const endDay = this.date[1].getFullYear() + String(this.date[1].getMonth() + 1).padStart(2, '0')
                        + String(this.date[1].getDate()).padStart(2, '0');
                    const res1 = await axios.get('/api/getTotalInOutQtyBySpecificItem', {
                        params: {
                            startDate: startDay,
                            endDate: endDay,
                            itmno: this.itmno
                        }
                    })
                    if (res1.data.err) {
                        console.log(res1.data.err);
                    } else if (res1.data.message) {
                        console.log(res1.data.message);
                    } else {
                        this.items = Object.values(res1.data);
                        console.log(this.items);
                        this.item.startDay = this.date[0];
                        this.item.endDay = this.date[1];
                        this.item.ITMNM = this.items[0].ITMNM;
                        this.item.TOTAL_BEGINNING_QTY = this.items[0].TOTAL_BEGINNING_QTY;
                        this.item.TOTAL_IN_QTY = this.items[0].TOTAL_IN_QTY;
                        this.item.TOTAL_OUT_QTY = this.items[0].TOTAL_OUT_QTY;
                        this.item.TOTAL_END_QTY = this.items[0].TOTAL_END_QTY;
                        this.item.TOTAL_CURRENT_QTY = this.items[0].TOTAL_CURRENT_QTY;
                        const res2 = await axios.get('/api/getPeriodSpecificItmIncomingDetail', {
                            params: {
                                startDate: startDay,
                                endDate: endDay,
                                itmno: this.itmno
                            }
                        });
                        this.incomingItems = Object.values(res2.data);
                        if (res2.data) {
                            const res3 = await axios.get('/api/getPeriodOutgoingDetail', {
                                params: {
                                    startDate: startDay,
                                    endDate: endDay,
                                    itmno: this.itmno
                                }
                            });
                            this.outgoingItems = Object.values(res3.data);
                        }
                    }

                } else {
                    alert("조회 기간을 입력해주세요")
                }
            } catch (err) {
                console.error("Data fetch error: ", err);
            }
            this.loading = false;
        },
        closeScreen() {
            this.$emit('close-modal');
            this.item = {};
        },
        isNumericChar(char) {
            return !isNaN(parseFloat(char)) && isFinite(char);
        }
    }
}
</script>

<style scoped>
.data-table-row td {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;

}

.data-table-row td {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;

}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 9999;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid black;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.modal-xl {
    max-width: 1600px;
}

.modal-table td,
.modal-table tr {
    text-align: center;
}

.modal-table td input {
    margin: 0 auto;
}

.box-qty-input {
    max-width: 150px;

}

.box-input-control {
    width: 100%;
    text-align: center;
}

.custom-header-border {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
}
.custom-font-title {
    font-size:xx-large;
    color:black;
}
.custom-text-color {
    color: black;
    font-weight:700;
}
</style>