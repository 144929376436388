<template>
    <div class="container h-100">

        <div class="row justify-content-center align-items-center mt-12 h-100">
            <div class="col-12 text-center">
                <img src="@/assets/img/seohan_logo.png" alt="Seohan Logo" class="logo" width="170" height="35.1">
            </div>
            <div class="col-12 col-md-7 col-lg-6 col-xl-5">
                <div class="card shadow">
                    <div class="card-body p-5">
                        <!-- <h3 class="card-title text-center mb-3">Login</h3> -->
                        <form @submit.prevent="login">
                            <!-- Username input -->
                            <div class="form-outline form-custom mb-4">
                                <input type="text" id="username" class="custom-input " v-model="username"
                                     required />
                                <label>username</label>
                                <span class="login-span-custom"></span>
                            </div>

                            <!-- Password input -->
                            <div class="form-outline form-custom mb-4">
                                <input type="password" id="password" class="custom-input" v-model="password"
                                     required />
                                <label>password</label>
                                <span class="login-span-custom"></span>
                            </div>

                            <!-- Submit button -->
                            <button type="submit" class="btn btn-info btn-login btn-lg btn-block">Login</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    data() {
        return {
            username: '',
            password: '',
        };
    },
    beforeMount() {

    },
    methods: {
        async login() {
            try {
                const response = await fetch('/api/login', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username: this.username,
                        password: this.password
                    }),
                });

                const data = await response.json();
                if (data.success) {
                    this.$emit('updateUser', data.username);
                    this.$router.push('/');
                } else {
                    alert('Login failed!');
                }
            } catch (error) {
                console.error('Login error:', error);
                alert('Login failed!');
            }
        }
    }
};
</script>
  
<style>
/* Additional styles for better appearance */
/* 입력 상자 스타일링 */
.form-custom {
    position: relative;
    width: 300px;
    margin-left: 35px;
    margin-top: 50px;
}

.custom-input {
    font-size: 15px;
    color: #222222;
    width: 350px;
    border: none;
    border-bottom: solid #aaaaaa 1px;
    padding-bottom: 10px;
    padding-left: 10px;
    position: relative;
    background: none;
    z-index: 5;
}

.custom-input::placeholder {
    color: #aaaaaa;
}

.custom-input:focus {
    outline: none;
}

.login-span-custom {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0%;
    /* right로만 바꿔주면 오 - 왼 */
    background-color: #666;
    width: 0;
    height: 2px;
    border-radius: 2px;
    transition: 0.5s;
}

label {
    position: absolute;
    color: #aaa;
    left: 10px;
    font-size: 20px;
    bottom: 8px;
    transition: all .2s;
}

.custom-input:focus~label,
.custom-input:valid~label {
    font-size: 16px;
    bottom: 40px;
    color: #666;
    font-weight: bold;
}

.custom-input:focus~span,
.custom-input:valid~span {
    width: 100%;
}

.logo {
    max-width: 100%;
    /* 이미지 너비 최대치 */
    height: auto;
    /* 이미지 높이 자동 조절 */
    margin-bottom: 2rem;
    /* 로고 아래 여백 */
}

.btn-login {
    background-color: #0B4DA2 !important
}

.btn-login:hover {
    border-color: #0B4DA2 !important
}

.form-control-lg {
    border-radius: 0.75rem;
    /* 둥근 모서리 */
    font-size: 1.25rem;
    /* 글꼴 크기 */
    padding: 0.75rem 1rem;
    /* 패딩 */
}

/* 로그인 버튼 스타일링 */
.btn-lg {
    padding: 0.75rem 1.25rem;
    /* 패딩 */
    border-radius: 0.75rem;
    /* 둥근 모서리 */
}

.container {
    height: calc(100vh - 56px);
    /* Adjust height to fill screen minus navbar height */
}

.login-page {
    background: url('../assets/img/seohan_main_banner01.png') no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    width: 100%;
}

.login-background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.card {
    border-radius: 1rem;
    /* Rounded border */
}

.form-control {
    border-radius: 0.5rem;
    /* Rounded form inputs */
}

.btn-block {
    border-radius: 0.5rem;
    /* Rounded button */
}
@media (max-width:1040px){
    .custom-input {
        width:250px;
    }
    .form-custom {
        margin-left:20px;
    }
}
@media (max-width: 760px) {
    .custom-input {
        width: 350px;
    }
}
@media (max-width: 400px) {
    .custom-input {
        width: 220px;
    }
}
</style>