<template>
    <div v-if="loading" class="loading-overlay">
        <div class="spinner"></div>
        Loading...
    </div>
    <div class="modal show d-block" id="productModal" tabindex="-1" aria-labelledby="productModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="d-flex modal-header justify-space-between">
                    <h5 class="modal-title" id="productModalLabel">Requested Parts</h5>
                    <v-btn icon="$close" variant="text" @click="closeScreen"></v-btn>

                </div>
                <div class="modal-body">
                    <v-card class="mt-1">
                        <v-card-text>
                            <v-window v-model="tab">
                                <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" single-line
                                    variant="outlined" hide-details></v-text-field>
                                <v-data-table :custom-filter="filterOnlyCapsText" :headers="headers" :items="items"
                                    :search="search" item-value="name" class="text-h6">

                                    <template #[`item`]="{ item }">
                                        <tr v-for="(itmno, index) in item.ITMNO" :key="index" class="data-table-row">
                                            <td v-if="index === 0" class="text-start" :rowspan="item.ITMNO.length">
                                                {{ item.BOX_ADD }}
                                            </td>
                                            <td v-if="index === 0" class="text-start" :rowspan="item.ITMNO.length">{{
                                                item.CONT_NO }}</td>
                                            <td v-if="isNumericChar(itmno.charAt(0))" class="custom-class text-center font-weight-black">{{ itmno.substring(0,5) + '-' + itmno.substring(5) }}</td>
                                            <td v-if="!isNumericChar(itmno.charAt(0))" class="custom-class text-center font-weight-black">{{ itmno.substring(0,6) + '-' + itmno.substring(6) }}</td>
                                            <td class="text-end">{{ item.QTY[index].toLocaleString() }}</td>
                                            <td class="text-end">{{ item.CO_GB[index] }}</td>
                                            <td class="text-end">{{ item.INDATE[index] }}</td>
                                            <td v-if="index === 0" class="text-end" :rowspan="item.ITMNO.length">
                                                <v-chip :color="item.SCAN_STATUS === '0' ? 'red' : 'green'"
                                                    class="text-uppercase text-h6" variant="flat" label size="large">
                                                    {{ item.SCAN_STATUS === '0' ? 'Not Scanned' : 'Scanned' }}
                                                </v-chip>
                                            </td>
                                            <td v-if="index === 0" class="text-end" :rowspan="item.ITMNO.length">
                                                <router-link :to="`/scanTransaction/${this.offsetNo}/${item.CONT_NO}`">
                                                    <v-list-item-title>
                                                        <v-icon icon="mdi-barcode-scan"></v-icon>

                                                    </v-list-item-title>
                                                </router-link>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-window>
                        </v-card-text>
                    </v-card>
                </div>

            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: ['products', 'offsetNo'],
    data() {
        return {
            loading: false,
            search: '',
            tab: null,
            headers: [
                {
                    title: 'Type',
                    align: 'start',
                    key: 'BOX_ADD'
                },
                {
                    title: 'Cont No',
                    align: 'start',
                    key: 'CONT_NO'
                },
                {
                    title: 'Item No',
                    align: 'end',
                    key: 'ITMNO'
                },
                {
                    title: 'Qty',
                    align: 'end',
                    key: 'QTY'
                },
                {
                    title: 'Supplier',
                    align: 'end',
                    key: 'CO_GB'
                },
                {
                    title: 'In Date',
                    align: 'end',
                    key: 'INDATE'
                },
                {
                    title: 'Status',
                    align: 'end',
                    key: 'SCAN_STATUS'
                },
                {
                    title: '',
                    align: 'end',
                }

            ],
            addedProducts: [],
            items: [],
        };
    },
    mounted() {
        this.loading = true;
        console.log(this.offsetNo);
        this.items = Object.values(this.products);
        console.log(this.items);
        this.loading = false;
    },
    computed: {
        // filteredProducts() {
        //     // for (let item of this.products) {
        //     //     this.items.push({
        //     //         'CONT_NO': item.CONT_NO
        //     //     })
        //     // }
        //     return this.products;
        // }
    },
    methods: {

        closeScreen() {
            this.$emit('close-modal');
        },
        isNumericChar(char) {
            return !isNaN(parseFloat(char)) && isFinite(char);
        }
    }
}
</script>
  
<style scoped>
.data-table-row td {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;

}

.data-table-row td {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;

}
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid black;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.modal-xl {
    max-width: 1200px;
}

.modal-table td,
.modal-table tr {
    text-align: center;
}

.modal-table td input {
    margin: 0 auto;
}

.box-qty-input {
    max-width: 150px;

}

.box-input-control {
    width: 100%;
    text-align: center;
}
</style>