<template lang="">
    <div v-if="loading" class="loading-overlay">
        <div class="spinner"></div>
        Loading...
    </div>
    <div class="container-fluid py-5">
        <div class="scan-mode box top-box mb-3 p-4 rounded-3 bg-white d-flex align-items-center shadow">
            <p class="font-weight-bold text-h4">Scan Parts</p>
        </div>
        
        
        <!-- <h4 class="mb-3 ml-5 mt-3">TRANSACTION REQUEST</h4>
            <v-divider></v-divider> -->
        <div class="justify-content-between d-flex">
            <div class="d-flex">           
                <p class="scan-mode black border shadow bg-white rounded pa-2 font-weight-bold mt-3 mr-3">Offsite No: {{ this.offsetNo }}</p>
                <p class="scan-mode d-flex align-center shadow bg-white black border rounded pa-2 font-weight-bold mt-3 mr-3" :class="`${this.scannedPart === this.totalQty ? 'text-white bg-green' : ''}`"><i class="material-symbols-outlined opacity-10 mr-1">barcode_scanner</i>Total Scanned Parts: {{ this.scannedPart }}/{{ this.totalQty }}</p>

                <p class="scan-mode scan-mode-button black border shadow bg-white rounded pa-2 mt-3 mr-3 font-weight-bold" @click="hideSidebar">
                    <v-icon icon="mdi-barcode-scan" class="mr-2"></v-icon>SCAN MODE
                </p>

                
            </div>
            <div v-if="totalQty === scannedPart" class="d-flex w-37">
                <v-btn v-if="offStatus === '1'" class="mt-3 mr-6 bg-green" @click="updateHeader">SUBMIT</v-btn>
                <v-btn v-if="offStatus === '2' || offStatus === '1'" class="mt-3 bg-red"  @click="generatePdf">
                    <v-icon icon="mdi-file-pdf-box"></v-icon>
                    PDF
                </v-btn>
            </div>
            
        </div>
        
        
        <div class="d-flex">
            <v-card class="mt-1 mr-3 w-70 custom-card scan-mode">
        
                <v-tabs v-model="tab" color="deep-purple-accent-4">
                    <v-tab value="1">Scan Table</v-tab>
                    <!-- <v-tab value="2">Shipping</v-tab> -->
                    <!-- <v-tab value="9">Completed</v-tab> -->
                </v-tabs>
                

                <v-card-text>
                    <v-window v-model="tab">
                        <v-window-item value="1">
                            <v-data-table
                                :items-per-page="itemsPerPage"
                                :headers="headers"
                                :items="partList"
                                density="compact"
                                item-value="name"
                                class="text-h3 scan-mode font-weight-black"
                                >
                                <!-- <template v-slot:top>
                            
                                </template> -->

<template #[`item`]="{ item }">
                                    <tr class="data-table-row font-weight-bold">
                                        <td>{{ partList.indexOf(item) + 1 }}</td>
                                        <td v-if="isNumericChar(item.ITMNO.charAt(0))" class="custom-class text-center font-weight-black text-h3">{{ item.ITMNO.substring(0,5) + '-' + item.ITMNO.substring(5) }}</td>
                                        <td v-if="!isNumericChar(item.ITMNO.charAt(0))" class="custom-class text-center font-weight-black text-h3">{{ item.ITMNO.substring(0,6) + '-' + item.ITMNO.substring(6) }}</td>
                                        <td class="text-center font-weight-black text-h3">{{ item.BOX1_QTY1.toLocaleString() }}</td>
                                        <td class="text-center font-weight-black text-h3">{{ item.SCANNED_QTY.toLocaleString() }}</td>
                                        <td class="text-center">
                                        <v-chip
                                            :color="item.SCAN_STATUS === '0' ? 'red' : 'green'"
                                            class="text-uppercase text-h4"
                                            variant="flat"
                                            label
                                            size="big"
                                        >
                                            {{ item.SCAN_STATUS === '0' ? 'Not Scanned' : 'Scanned' }}
                                        </v-chip>
                                        </td>
                                    </tr>
                                </template>

</v-data-table>
</v-window-item>

</v-window>
</v-card-text>
</v-card>
<v-card class="mt-1 w-30 scan-mode">
    <v-tabs v-model="tab" color="deep-purple-accent-4">
        <v-tab value="1">Scan Table</v-tab>
        <!-- <v-tab value="2">Shipping</v-tab> -->
        <!-- <v-tab value="9">Completed</v-tab> -->
    </v-tabs>
    <v-card-text>
        <v-window class="h-100" v-model="tab">
            <v-window-item value="1">
                <div class="border rounded ma-3 mw-90 h-75 scan-screen justify-center align-center">
                    <v-row class="fill-height" align="center" justify="center">
                        <v-col cols="12" class="d-flex justify-center align-center custom-height">
                            <p class="text-white font-weight-bold text-h2" id="status-text"></p>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center align-center custom-height" id="status-box">
                            <p class="text-white font-weight-bold text-h2" id="status-text2"></p>
                        </v-col>
                    </v-row>


                </div>

                <div v-if="totalQty !== scannedPart" class="d-flex ml-10 mr-10">
                    <p class="font-weight-bold mt-3 mr-2">barcode scan: </p>
                    <v-text-field ref="barcodeInput" :disabled="loading" v-model="barcodeInput" hide-details="auto"
                        id="barcode-scanner" @keyup.enter="scanBarcode"></v-text-field>

                </div>
            </v-window-item>
        </v-window>
    </v-card-text>


</v-card>
</div>

</div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            itemsPerPage: 100,
            containerNo: '',
            partList: [],
            headers: [
                {
                    title: 'No',
                    align: 'start',
                    sortable: false,
                    key: ''
                },
                {
                    title: 'ITEM No',
                    align: 'center',
                    sortable: false,
                    key: 'ITMNO'
                },
                {
                    title: 'Qty',
                    align: 'center',
                    sortable: false,
                    key: 'BOX1_QTY1'
                },
                {
                    title: 'Scan Qty',
                    align: 'center',
                    sortable: false,
                    key: 'SCANNED_QTY'
                },
                {
                    title: 'Status',
                    align: 'center',
                    sortable: false,
                    key: 'SCAN_STATUS'
                }
            ],
            tab: null,
            scannedPart: 0,
            totalQty: 0,
            barcodeInput: '',
            scanCheck: false,
            totalScanComplete: false,
            loading: false,
            offStatus: '',
            destination: '',
            offsetIdx: '',
            estimatedDate: '',
            isFinished: false,
            originPartList: [],
            ordNo: '',
        }
    },
    props: {
        offsetNo: {
            type: String,
            required: true,
        },
        contNo: {
            type: String,
            required: true,
        }
    },
    async mounted() {
        await this.getOffsiteHeader(this.offsetNo, this.contNo);
        await this.fetchPartData(this.offsetNo, this.contNo);

        let isFinish = true;

        if (!await this.checkIsSubmitted()) {
            console.log("isnot shipping")
            for (const part of this.partList) {
                if (part.SCAN_STATUS == '0') {
                    isFinish = false;
                }
            }
            if (isFinish) {
                this.offStatus = '1'
            }
        }

        await this.hideSidebar();
        this.isFinished = isFinish;
        this.$nextTick(() => {
            if (this.$refs.barcodeInput) {
                this.$refs.barcodeInput.focus();
            }
        });

        // document.getElementById('barcode-scanner').addEventListener('keypress', function (e) {
        //     if (e.charCode === 29) {
        //         // this.value += String.fromCharCode(e.which);
        //         this.value += ':'
        //     } else if (e.charCode === 30) {
        //         this.value += '*'
        //     } else if (e.charCode === 4) {
        //         this.value += 'EOT'
        //     }
        // })

    },
    methods: {
        async hideSidebar() {
            const sidenavShow = document.getElementsByClassName("sidenav")[0];
            const mainContent = document.getElementsByClassName("main-content")[0];
            const specificContents = document.getElementsByClassName('scan-mode');
            if (sidenavShow.classList.contains("hide-sidebar")) {
                sidenavShow.classList.remove("hide-sidebar");
                mainContent.classList.remove("maximize-content");
                for (const content of specificContents) {
                    if (!content.classList.contains("bg-white")) {
                        content.classList.add("bg-white")
                    }
                    if (!content.classList.contains("border")) {
                        content.classList.add("border");
                    }
                    content.classList.remove("bg-black");
                    content.classList.remove("on-scan");
                }
            } else {
                sidenavShow.classList.add("hide-sidebar");
                mainContent.classList.add("maximize-content");
                for (const content of specificContents) {
                    if (content.classList.contains("bg-white")) {
                        content.classList.remove("bg-white");
                    }
                    if (content.classList.contains("border")) {
                        content.classList.remove("border");
                    }
                    content.classList.add("bg-black");
                    content.classList.add("on-scan");
                }
            }

        },
        async scanBarcode() {
            // const controlChars = {
            //     29: '*',
            //     4: 'EOT',
            //     30: ':'
            // }
            this.loading = true;
            let scanData = {};
            let scanDataIdx = 0;
            let errorMsg = ''

            for (const part of this.originPartList) {
                if (part.BOX1_QR == this.barcodeInput || part.BOX1_QR1 == this.barcodeInput) {
                    if (part.SCAN_STATUS == '1') {
                        errorMsg = 'Already scanned';
                        this.scanCheck = false;
                    } else {
                        scanData = part;
                        this.scanCheck = true;
                    }

                    break;

                }
                scanDataIdx += 1;
            }
            const bigBoxCount = this.originPartList.filter(part => part.BOX1_QR === this.barcodeInput).length;
            console.log(bigBoxCount);
            if (this.scanCheck) {
                try {
                    if (bigBoxCount > 1) {
                        for (let part of this.partList) {
                            if (part.ITMNO == scanData.ITMNO) {
                                part.SCANNED_QTY += scanData.BOX1_QTY;
                                if (part.SCANNED_QTY == part.BOX1_QTY1) {
                                    part.SCAN_STATUS = '1';
                                }
                                break;
                            }
                        }
                        const response = await axios.post('/api/saveScanData', {
                            partData: scanData,
                            isBig: true
                        });
                        if (response.data.success) {
                            if (document.getElementsByClassName('scan-screen')[0].classList.contains('custom-scan-failed')) {
                                document.getElementsByClassName('scan-screen')[0].classList.remove('custom-scan-failed');
                            }
                            // Add this part to play the audio file
                            const audio = new Audio(require('@/assets/OK.wav'));
                            audio.play();
                            document.getElementsByClassName("scan-screen")[0].classList.add("custom-scan-success");
                            document.getElementById("status-text").innerText = "OK";
                            document.getElementById("status-box").classList.add("success-screen-part");
                            document.getElementById("status-text2").innerText = scanData.ITMNO;
                            this.scannedPart += bigBoxCount;
                            this.originPartList.forEach(part => {
                                if (part.BOX1_QR == this.barcodeInput) {
                                    part.SCAN_STATUS = '1';
                                }
                            })
                        } else {
                            alert('Saving scan data is failed');

                        }
                    } else {
                        for (let part of this.partList) {
                            if (part.ITMNO == scanData.ITMNO) {
                                part.SCANNED_QTY += scanData.BOX1_QTY1;
                                if (part.SCANNED_QTY == part.BOX1_QTY1) {
                                    part.SCAN_STATUS = '1';
                                }
                                break;
                            }
                        }
                        const response = await axios.post('/api/saveScanData', {
                            partData: scanData,
                            isBig: false,
                        });
                        if (response.data.success) {
                            if (document.getElementsByClassName('scan-screen')[0].classList.contains('custom-scan-failed')) {
                                document.getElementsByClassName('scan-screen')[0].classList.remove('custom-scan-failed');
                            }
                            // Add this part to play the audio file
                            const audio = new Audio(require('@/assets/OK.wav'));
                            audio.play();
                            document.getElementsByClassName("scan-screen")[0].classList.add("custom-scan-success");
                            document.getElementById("status-text").innerText = "OK";
                            document.getElementById("status-box").classList.add("success-screen-part");
                            document.getElementById("status-text2").innerText = scanData.ITMNO;
                            this.scannedPart += 1;
                            this.originPartList[scanDataIdx].SCAN_STATUS = '1';
                        } else {
                            alert('Saving scan data is failed');

                        }
                    }

                } catch (err) {
                    console.error('Scan data save error: ', err);
                }
            } else {
                if (errorMsg.length == 0) {
                    errorMsg = 'No matched data';
                }
                if (document.getElementsByClassName('scan-screen')[0].classList.contains('custom-scan-success')) {
                    document.getElementsByClassName('scan-screen')[0].classList.remove('custom-scan-success');
                }
                const audio = new Audio(require('@/assets/NG.wav'));
                audio.play();
                document.getElementsByClassName("scan-screen")[0].classList.add("custom-scan-failed");
                document.getElementById("status-text").innerText = "NG";
                document.getElementById("status-box").classList.add("success-screen-part");
                document.getElementById("status-text2").innerText = errorMsg + "";
            }

            this.barcodeInput = '';
            if (this.totalQty === this.scannedPart) {
                await this.updateHeader();
            }
            this.scanCheck = false;
            this.$nextTick(() => {
                if (this.$refs.barcodeInput) {
                    this.$refs.barcodeInput.focus();
                }
            });
            this.loading = false;
        },
        async fetchPartData(offsetNo, contNo) {
            this.loading = true;
            try {
                const response = await axios.get('/api/getTransactionParts', {
                    params: {
                        offsetNo: offsetNo,
                        contNo: contNo
                    }

                });
                this.originPartList = response.data;
                let tempList = [];
                this.ordNo = response.data[0].ORD_NO;
                for (let part of response.data) {
                    if (!Object.hasOwnProperty.call(tempList, part.ITMNO.trim())) {
                        if (part.SCAN_STATUS == '1') {
                            tempList[part.ITMNO.trim()] = {
                                'ITMNO': part.ITMNO.trim(),
                                'BOX1_QTY1': part.BOX1_QTY1,
                                'SCANNED_QTY': part.BOX1_QTY1,
                                'SCAN_STATUS': '0'
                            }
                        } else {
                            tempList[part.ITMNO.trim()] = {
                                'ITMNO': part.ITMNO.trim(),
                                'BOX1_QTY1': part.BOX1_QTY1,
                                'SCANNED_QTY': 0,
                                'SCAN_STATUS': '0'
                            }
                        }
                    } else {
                        if (part.SCAN_STATUS == '1') {
                            tempList[part.ITMNO.trim()].SCANNED_QTY += part.BOX1_QTY1;
                        }
                        tempList[part.ITMNO.trim()].BOX1_QTY1 += part.BOX1_QTY1;
                    }
                }
                for (let part of Object.values(tempList)) {
                    if (part.BOX1_QTY1 == part.SCANNED_QTY) {
                        part.SCAN_STATUS = '1'
                    }
                }
                this.partList = Object.values(tempList);
                console.log(this.originPartList)
                this.totalQty = this.originPartList.length
            } catch (err) {
                console.error("Data fetch error: ", err);
            }
            this.countScannedPart();
            this.loading = false;
        },
        async getOffsiteHeader(offsetNo) {
            this.loading = true;
            try {
                const response = await axios.get('/api/getOffsiteHeader', {
                    params: {
                        offsetNo: offsetNo
                    }
                })
                if (response.data.err) {
                    console.log(response.err);
                } else if (response.data.message) {
                    console.log(response.data.message);
                } else {
                    console.log(response.data);
                    this.offStatus = response.data[0].OFFSET_STATUS;
                    this.destination = response.data[0].DEST;
                    this.offsetIdx = response.data[0].OFFSET_IDX;
                    this.estimatedDate = response.data[0].ESTIMATE_DATE;
                    this.containerNo = response.data[0].CONT_NO;

                }

            } catch (err) {
                console.error("Status fetch error: ", err);
            }
            this.loading = false;
        },
        async updateHeader() {
            this.loading = true;

            try {
                const res = await axios.get('/api/checkShippingStatus', {
                    params: {
                        offsetNo: this.offsetNo
                    }
                })
                if (res.data.isFinished) {
                    const response = await axios.post('/api/updateShippingData', {
                        containerNo: this.containerNo,
                        offsetNo: this.offsetNo,
                        scannedParts: this.partList
                    });
                    if (response.data.success) {
                        alert("Successfully Submitted")

                        this.offStatus = '2'
                        await this.generatePdf();
                    } else {
                        if (response.data.err) {
                            console.log(response.err);
                        } else if (response.data.message) {
                            console.log(response.message);
                        } else {
                            alert('Sumitting is failed')
                        }
                    }
                } else {
                    const response = await axios.post('/api/updateShippingPart', {
                        scannedParts: this.originPartList
                    });
                    if (response.data.success) {
                        alert("Successfully Submitted")
                        this.offStatus = '2'
                        await this.generatePdf();
                    } else {
                        if (response.data.err) {
                            console.log(response.err);
                        } else if (response.data.message) {
                            console.log(response.message);
                        } else {
                            alert('Sumitting is failed')
                        }
                    }
                }
            } catch (err) {
                console.error("update header error: ", err);
            }


            this.loading = false;
        },
        countScannedPart() {
            this.loading = true;
            try {
                this.originPartList.forEach(part => {
                    if (part.SCAN_STATUS == 1) {
                        this.scannedPart += 1;
                    }
                })
            } catch (err) {
                console.error("Count parts error: ", err);
            }
            this.loading = false;
        },
        async checkIsSubmitted() {
            this.loading = true;
            try {
                const response = await axios.get('/api/checkPartIsShipping', {
                    params: {
                        offsetNo: this.originPartList[0].OFFSET_NO,
                        contNo: this.contNo
                    }
                });
                if (response.data.isShipping) {
                    this.offStatus = '2'
                    this.loading = false;
                    return true;
                } else {
                    this.offStatus = '0'
                    this.loading = false;
                    return false;

                    // return false;
                }
            } catch (err) {
                console.error("Checking whether part is shipping is failed");
            }

        },
        // PDF 생성 메서드
        async generatePdf() {
            this.loading = true;
            try {
                console.log(this.partList);
                const newPartList = this.partList
                const res1 = await axios.post('/api/getSpecificPartByScannedItem', {
                    partList: newPartList,
                    contNo: this.contNo
                })
                if (res1.data.err) {
                    console.log(res1.err);
                } else if (res1.data.message) {
                    console.log(res1.message);
                } else {
                    const tableData = Object.values(res1.data);
                    const response = await axios.post('/api/create-pdf', {
                        tableData: tableData, // 현재 테이블 데이터를 전송
                        offsetNo: this.offsetNo,
                        destination: this.destination,
                        offsetIdx: this.offsetIdx,
                        estimatedDate: this.estimatedDate,
                        contNo: this.contNo
                    }, {
                        responseType: 'blob', // PDF 파일을 Blob 형태로 응답받음
                    });

                    // Blob 데이터로 PDF 파일을 생성 후 프린트
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const fileURL = window.URL.createObjectURL(blob);
                    const printWindow = window.open(fileURL);

                    // 프린트 창에서 PDF 로딩을 확인하고 프린트 실행
                    printWindow.onload = function () {
                        try {
                            console.log("hello")
                            setTimeout(() => { // PDF 로드를 기다립니다.
                                printWindow.focus(); // 프린트 창에 포커스를 맞춥니다.
                                printWindow.print(); // 프린트를 실행합니다.
                                printWindow.onafterprint = function () {
                                    printWindow.close(); // 프린트 후 창을 닫습니다.
                                };
                            }, 1000); // 1000ms 후에 프린트를 시도합니다.
                        } catch (e) {
                            console.error('Print Error: ', e);
                        }
                    };
                }

            } catch (err) {
                console.error('PDF 생성 오류: ', err);
            }
            this.loading = false;
        },
        isNumericChar(char) {
            return !isNaN(parseFloat(char)) && isFinite(char);
        }
    }
}
</script>
<style scoped>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 9999;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid black;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.custom-card {
    min-height: 800px;
}

.custom-scan-input {
    color: #495057 !important;
    border: 1px solid #d2d6da !important;
}

.scan-screen {
    display: block;
    transition: 0.5s;
    min-height: 500px;
}

.custom-scan-success {
    background-color: #4CAF50 !important;
}

.custom-scan-failed {
    background-color: #F44336 !important;
}

.custom-container-input {
    border: 1px solid black;
    height: 40px;
}

.custom-height {
    min-height: 250px;
}

.on-scan {
    border: white 1px solid;
}

.success-screen-part {
    border-top: 2px black solid;
}

.scan-mode-button:hover {
    background: grey;
}

.data-table-row td {
    border-left: 1px solid white;
    border-right: 1px solid white !important;
    border-bottom: 1px solid white !important;

}

.data-table-row td {
    border-left: 1px solid white;
    border-right: 1px solid white !important;
    border-bottom: 1px solid white !important;

}
</style>