<template>
    <div class="modal show d-block" id="productModal" tabindex="-1" aria-labelledby="productModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="productModalLabel">제품 추가</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <!-- 왼쪽 절반: 제품 리스트 테이블 -->
                        <div class="col-md-8">
                            <!-- 제품 검색 -->
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title border-bottom">제품 검색</h5>
                                    <!-- Flex 컨테이너 -->
                                    <div class="d-flex justify-content-between">
                                        <!-- 검색창 -->

                                        <input type="text" class="border form-control"
                                            placeholder="  Part Number Search..." v-model="searchQuery"
                                            style="flex-grow: 1; margin-right: 10px; max-width: 30%;">

                                        <!-- 버튼 그룹 -->
                                        <div>
                                            <button type="button" class="btn btn-success"
                                                @click="addSelectedProducts">ADD</button>
                                            <!-- <button type="button" class="btn btn-secondary"
                                                @click="$emit('close-modal')">Close</button> -->
                                        </div>
                                    </div>
                                    <table class="table modal-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <input type="checkbox" v-model="allSelected" @change="toggleAll">
                                                </th>
                                                <th scope="col">#</th>
                                                <th scope="col">컨테이너 번호</th>
                                                <th scope="col">부품번호</th>
                                                <th scope="col">부품이름</th>
                                                <th scope="col box-qty-input">수량</th>
                                                <th scope="col">로트번호</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in filteredProducts" :key="index">
                                                <td>
                                                    <input type="checkbox" v-model="item.selected">
                                                </td>
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ item.CONT_NO }}</td>
                                                <td v-if="isNumericChar(item.ITMNO.charAt(0))"
                                                    class="custom-class text-center font-weight-black">{{
                                                item.ITMNO.trim().substring(0, 5) + '-' +
                                                item.ITMNO.trim().substring(5) }}</td>
                                                <td v-if="!isNumericChar(item.ITMNO.charAt(0))"
                                                    class="custom-class text-center font-weight-black">{{
                                                item.ITMNO.trim().substring(0, 6) + '-' +
                                                item.ITMNO.trim().substring(6) }}</td>

                                                <td>{{ item.ITM_NM }}</td>
                                                <td class="box-qty-input">{{ item.BOX2_QTY.toLocaleString() }}
                                                </td>
                                                <td>{{ item.BOX_LOT }}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                        <!-- 오른쪽 절반: 추가된 제품 리스트 -->
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">추가된 제품 리스트</h5>
                                    <ul class="list-group">
                                        <li class="list-group-item d-flex justify-content-between align-items-center"
                                            v-for="product in addedProducts" :key="product.id">
                                            {{ product.ITMNO }}
                                            <button @click="removeProduct(product)" class="btn btn-sm">
                                                <v-icon icon="mdi-delete" size="24"></v-icon>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="card-footer text-end">
                                    <button type="button" class="btn btn-primary"
                                        @click="submitAddedProducts">SUBMIT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['products'],
    data() {
        return {
            searchQuery: '',
            addedProducts: [],
            filteredProducts: [], // 초기값을 빈 배열로 설정
            allSelected: false, // 전체 선택 여부
        };
    },
    watch: {
        searchQuery() {
            this.filterProducts();
        },
        products: {
            handler(newProducts) {
                this.filterProducts(newProducts);
            },
            immediate: true
        },
        filteredProducts: {
            handler() {
                this.updateAllSelected();
            },
            deep: true
        }
    },
    methods: {
        filterProducts(products = this.products) {
            if (this.searchQuery) {
                this.filteredProducts = products.filter(product =>
                    product.ITMNO.includes(this.searchQuery) ||
                    product.CONT_NO.includes(this.searchQuery)
                );
            } else {
                this.filteredProducts = products;
            }
        },
        addSelectedProducts() {
            const selectedProducts = this.filteredProducts.filter(p => p.selected);
            if (selectedProducts.length != 0) {
                selectedProducts.forEach(product => {
                    if (!this.addedProducts.some(addedProduct => addedProduct.ITMNO === product.ITMNO && addedProduct.CONT_NO === product.CONT_NO)) {
                        this.addedProducts.push(product);
                    }
                });

                selectedProducts.forEach(selectedProduct => {
                    const index = this.filteredProducts.findIndex(p => p.ITMNO === selectedProduct.ITMNO && p.CONT_NO === selectedProduct.CONT_NO);
                    if (index > -1) {
                        this.filteredProducts.splice(index, 1);
                    }
                });
            }

            this.$emit('added-products', this.addedProducts);
            this.resetSelection();
        },
        resetSelection() {
            this.products.forEach(p => p.selected = false);
        },
        removeProduct(product) {
            // addedProducts에서 해당 제품을 제거합니다.
            const index = this.addedProducts.findIndex(p => p.ITMNO === product.ITMNO && p.CONT_NO === product.CONT_NO);
            if (index > -1) {
                this.addedProducts.splice(index, 1);
            }

            // filteredProducts에 해당 제품을 다시 추가합니다.
            this.filteredProducts.push(product);

            // 검색 결과 갱신
            this.filterProducts();
        },
        submitAddedProducts() {
            this.$emit('submit-products', this.addedProducts);
            this.$emit('close-modal');
        },
        toggleAll() {
            this.filteredProducts.forEach(product => {
                product.selected = this.allSelected;
            });
        },
        updateAllSelected() {
            this.allSelected = this.filteredProducts.length && this.filteredProducts.every(product => product.selected);
        },
        isNumericChar(char) {
            return !isNaN(parseFloat(char)) && isFinite(char);
        }
    }
}
</script>

<style scoped>
.modal-xl {
    max-width: 1540px;
}

.modal-table td,
.modal-table tr {
    text-align: center;
}

.modal-table td input {
    margin: 0 auto;
}

.box-qty-input {
    max-width: 150px;

}

.box-input-control {
    width: 100%;
    text-align: center;
}
</style>