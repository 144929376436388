<template lang="">
    <div v-if="loading" class="loading-overlay">
        <div class="spinner"></div>
        Loading...
    </div>
    <div class="container-fluid py-5">
        <div class="box top-box mb-3 p-4 rounded-3 bg-white d-flex align-items-center shadow">
            <h4 class="font-weight-bold">Warehouse Dashboard</h4>
        </div>
        
        <v-card class="mt-1">
        
            <v-tabs v-model="tab" color="deep-purple-accent-4">
                <v-tab value="1">Warehouse Table</v-tab>
            </v-tabs>
            
            <v-card-text>
                
                <v-window v-model="tab">
                    <v-window-item value="1">
                        <div class="d-flex mb-3">
                            <vue-datepicker v-model="date" range placeholder="Start Typing ..."
                        :text-input="textInputOptions" :is-24="false" :enable-time-picker="false"></vue-datepicker>
                        <v-btn prepend-icon="mdi-magnify" class="ml-5" @click="fetchInItemQty">Inquiry</v-btn>
                        </div>
                        
                        <v-text-field
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            single-line
                            variant="outlined"
                            hide-details
                        >   
                        </v-text-field>
                        <v-data-table
                            :items-per-page="itemsPerPage"
                            :custom-filter="filterOnlyCapsText"
                            :headers="headers"
                            :items="itemsList"
                            :search="search"
                            class="custom-min-height"
                        >
                            <template v-slot:[`item`]="{ item, index }">
                                <tr class="data-table-row" @click="fetchDetailInfo(item)">
                                    <td class="text-start">{{ index + 1 }}</td>
                                    <td class="custom-class text-center font-weight-black">{{ item.ITMNO }}</td>
                                    <td class="text-center">{{ item.ITMNM }}</td>
                                    <td class="text-end">{{ item.TOTAL_BEGINNING_QTY?.toLocaleString() }}</td>
                                    <td class="text-end text-green-darken-1">{{ item.TOTAL_IN_QTY?.toLocaleString() }}</td>
                                    <td class="text-end text-red-darken-1">
                                        {{ item.TOTAL_OUT_QTY?.toLocaleString() }}
                                        <span class="material-symbols-outlined vertical-align">arrow_downward_alt</span>
                                    </td>
                                    <td class="text-end">{{ item.TOTAL_END_QTY?.toLocaleString() }}</td>
                                    <td class="text-end">{{ item.TOTAL_CURRENT_QTY?.toLocaleString() }}</td>
                                </tr>
                            </template>
                        </v-data-table>

</v-window-item>

</v-window>
</v-card-text>
</v-card>
<ShowPartInOutDetailModal v-if="isModalVisible" :itm="itm" :incomingDetail="incomingDetail"
    :outgoingDetail="outgoingDetail" @close-modal="isModalVisible = false; itm = {}" />
</div>
</template>
<script>
import axios from 'axios';
import VueDatepicker from '@vuepic/vue-datepicker';
import ShowPartInOutDetailModal from '@/components/Ui/ShowPartInOutDetailModal.vue';
export default {
    components: {
        VueDatepicker,
        ShowPartInOutDetailModal
    },
    data() {
        return {
            tab: null,
            search: '',
            itm: {},
            incomingDetail: [],
            outgoingDetail: [],
            isModalVisible: false,
            headers: [
                {
                    title: 'No',
                    align: 'start',
                    key: ''
                },
                {
                    title: 'Item No',
                    align: 'center',
                    key: 'ITMNO'
                },
                {
                    title: 'Item Name',
                    align: 'center',
                    key: 'ITMNM'
                },
                {
                    title: '기초재고',
                    align: 'end',
                    key: 'TOTAL_BEGINNING_QTY'
                },
                {
                    title: '입고',
                    align: 'end',
                    key: 'TOTAL_IN_QTY'
                },
                {
                    title: '출고',
                    align: 'end',
                    key: 'TOTAL_OUT_QTY'
                },
                {
                    title: '기말재고',
                    align: 'end',
                    key: 'TOTAL_END_QTY'
                },
                {
                    title: '현재고',
                    align: 'end',
                    key: 'TOTAL_CURRENT_QTY'
                }
            ],
            loading: false,
            itemsList: [],
            date: null,
            itemsPerPage: 100,
            startDay: '',
            endDay: '',
        };
    },
    computed: {

    },
    async mounted() {
        await this.getWeekStartAndEndDates();
        await this.fetchInItemQty();
    },
    methods: {
        async getWeekStartAndEndDates() {
            const newDate = new Date();

            // 월의 시작일 (첫날)
            const startDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1);

            // 월의 마지막일
            const endDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
            this.date = [startDate, endDate]
        },
        async fetchDetailInfo(item) {
            this.loading = true;
            try {
                console.log(item);
                this.itm = {
                    ITMNO: item.ITMNO,
                    ITMNM: item.ITMNM,
                    TOTAL_BEGINNING_QTY: item.TOTAL_BEGINNING_QTY,
                    TOTAL_IN_QTY: item.TOTAL_IN_QTY,
                    TOTAL_OUT_QTY: item.TOTAL_OUT_QTY,
                    TOTAL_END_QTY: item.TOTAL_END_QTY,
                    TOTAL_CURRENT_QTY: item.TOTAL_CURRENT_QTY,
                    startDay: this.date[0],
                    endDay: this.date[1],
                }
                const response = await axios.get('/api/getPeriodSpecificItmIncomingDetail', {
                    params: {
                        startDate: this.startDay,
                        endDate: this.endDay,
                        itmno: item.ITMNO
                    }
                })
                this.incomingDetail = response.data;
                console.log(this.incomingDetail);
                if (response.data) {
                    const res1 = await axios.get('/api/getPeriodOutgoingDetail', {
                        params: {
                            startDate: this.startDay,
                            endDate: this.endDay,
                            itmno: item.ITMNO
                        }
                    });

                    this.outgoingDetail = res1.data;
                    this.isModalVisible = true;
                }

            } catch (err) {
                console.error("Data fetch error: ", err);
            }
            this.loading = false;
        },
        // 입출고 데이터 가져오는 메서드
        async fetchInItemQty() {
            this.loading = true;
            try {
                if (this.date != null) {
                    this.startDay = this.date[0].getFullYear() + String(this.date[0].getMonth() + 1).padStart(2, '0')
                        + String(this.date[0].getDate()).padStart(2, '0');
                    this.endDay = this.date[1].getFullYear() + String(this.date[1].getMonth() + 1).padStart(2, '0')
                        + String(this.date[1].getDate()).padStart(2, '0');
                    const response = await axios.get('/api/getTotalInOutQtyByItem', {
                        params: {
                            startDate: this.startDay,
                            endDate: this.endDay
                        }
                    });
                    if (response.data.err) {
                        console.log(response.data.err);
                    } else if (response.data.message) {
                        console.log(response.data.message);
                    } else {

                        this.itemsList = Object.values(response.data);
                        console.log(this.itemsList);
                    }
                } else {
                    alert("Select the date!");
                }


            } catch (err) {
                console.error('Data fetch error: ', err);
            }
            this.loading = false;

        },
        isNumericChar(char) {
            return !isNaN(parseFloat(char)) && isFinite(char);
        }


    }
}
</script>
<style scoped>
.custom-min-height {
    min-height: 400px;
}

.vertical-align {
    vertical-align: middle;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 9999;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid black;
    width: 40px;
    height: 40px;
    z-index: 9999;
    animation: spin 1s linear infinite;
}

.data-table-row:hover {
    background-color: #f5f5f5;
    /* 마우스 오버 시 배경색 변경 */
    cursor: pointer;
}

.data-table-row td {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;

}

.data-table-row td {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;

}
</style>