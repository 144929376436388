<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">Current Delivery Status</h6>
        <button
          type="button"
          class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title=""
          data-bs-original-title="See Current State"
        >
          <i class="fas fa-info" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="p-3 pb-0 mt-4 card-body">
      <div class="row">
        <div class="col-7 text-start">
          <pie-chart :chart="{
              labels: status.labels,
              datasets: {
                label: 'Status',
                data: [requestStatus, shippingStatus, completStatus],
              },
            }"
          />
        </div>
        
        <div class="my-auto col-5">
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-red"></i>
            <span class="text-xs text-dark">{{ status.labels[0] }}</span>
          </span>
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-orange"></i>
            <span class="text-xs text-dark">{{ status.labels[1] }}</span>
          </span>
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-green"></i>
            <span class="text-xs text-dark">{{ status.labels[2] }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="p-3 pt-0 pb-0 card-footer d-flex align-items-center">
      <div class="w-60">
        <p class="text-sm">
          Current delivery status overview, Order request: {{ requestStatus }} item(s) , In transit: {{ shippingStatus }} item(s),
           Delivered: {{ completeStatus }} item(s)
        </p>
      </div>
      <div class="w-40 text-end">
        <a class="mb-0 btn bg-light text-end" href="/transactionRequest">Detail</a>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "@/components/Charts/StatusPieChart.vue";
export default {
  name: "ChannelsChartCard",
  components: {
    PieChart,
  },
  props: {
    status: {
      type: Object,
      required: true,
      labels: Array,
      data: Array
    }
  },
  mounted() {
    this.requestStatus = this.status.data[0]
  },
  data() {
    return {
      requestStatus: this.status.data[0],
      shippingStatus: this.status.data[1],
      completStatus: this.status.data[2]
    }
  }
};
</script>
