<template>
  <aside id="sidenav-main" class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3"
    :class="`${isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'
      } ${sidebarType}`">
    <div class="sidenav-header">
      <i id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"></i>
      <router-link class="m-0 navbar-brand" to="/" :username=username>
        <img src="../../assets/img/seohan_icon.png" class="navbar-brand-img h-70" alt="main_logo" />
        <span class="ms-1 font-weight-bold text-white">SAU Storage System</span>
      </router-link>
    </div>
    <hr class="horizontal light mt-0 mb-2" />
    <sidenav-list :username=username />
    <button class="material-symbols-outlined absolute-icon" @click="miniSidebar">
      {{isDisplay ? 'close_fullscreen' : 'open_in_full'}}
    </button>

  </aside>
</template>
<script>
import SidenavList from "./MySidenavList.vue";
import logo from "@/assets/img/logo-ct.png";
import logoDark from "@/assets/img/logo-ct-dark.png";
import { mapState } from "vuex";
export default {
  name: "SideIndex",
  props: {
    username: String,
  },
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoDark,
      isDisplay: true,
    };
  },
  computed: {
    ...mapState(["isRTL", "sidebarType", "isDarkMode"]),
  },
  methods: {
    miniSidebar() {
      const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];
      if (sidenavShow.classList.contains("g-sidenav-pinned")) {
        sidenavShow.classList.remove("g-sidenav-pinned");
        sidenavShow.classList.add("g-sidenav-hidden");
        this.isDisplay = true;
      } else {
        sidenavShow.classList.remove("g-sidenav-hidden");
        sidenavShow.classList.add("g-sidenav-pinned");
        this.isDisplay = false;
      }
      if (this.isDisplay) {
        this.isDisplay = false
      } else {
        this.isDisplay = true;
      }
    },
    minimizeSidebar() {
      return this.$route.meta.isLoginPage;
    },
  }
};
</script>
<style>

#sidenav-main {
  overflow-y: hidden;
  overflow-x:hidden;

}

.absolute-icon {
  position: absolute;
  bottom: 2%;
  /* 하단에서의 거리 */
  right: 10px;
  /* 오른쪽에서의 거리 */
  z-index: 1000;
  /* 필요한 경우 다른 요소 위에 표시되도록 z-index 설정 */
}

.isDisplay {
  width: 2% !important;
}

.disable-content {
  display: none !important;
}

.hide-sidebar {
    display:none !important;
}
</style>
