import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomePage.vue';
import Login from '../views/WmsLogin.vue';
import wmsStore from '../views/wms_store/OffsetStore.vue';
import MainInvoice from '@/views/wms_store/MainInvoice.vue';
import warehouseDashboard from '@/views/master/WarehouseDashboard.vue';
import partDashboard from '@/views/master/PartsMaster.vue';
import transactionRequest from '@/views/master/TransactionRequestMaster.vue';
import transactionScan from '@/views/master/TransactionScan.vue';
import store from '@/store/index';
import axios from 'axios';
const routes = [
    {
        path: '/',
        component: Home,
        meta: { requiresAuth: true, isLoginPage: false } // 로그인이 필요한 페이지
    },
    {
        path: '/login',
        component: Login,
        meta: { isLoginPage: true, requiresAuth: false }
    },
    {
        path: '/wms-store',
        component: wmsStore,
        meta: { requiresAuth: true, isLoginPage: false } // 로그인이 필요한 페이지
    },
    {
        path: '/mainTransaction',
        component: MainInvoice,
        meta: { requiresAuth: true, isLoginPage: false } // 로그인이 필요한 페이지
    },
    {
        path: '/partDashboard',
        component: partDashboard,
        meta: { requiresAuth: true, isLoginPage: false} // 로그인이 필요한 페이지
    },
    {
        path: '/transactionRequest',
        name: transactionRequest,
        component: transactionRequest,
        meta: { requiresAuth: true, isLoginPage: false} // 로그인이 필요한 페이지
    },
    {
        path: '/scanTransaction/:offsetNo/:contNo',
        component: transactionScan,
        props: true,
        meta: { requiresAuth: true, isLoginPage: false } // 로그인이 필요한 페이지
    },
    {
        path: '/warehouseDashboard',
        component: warehouseDashboard,
        meta: { requiresAuth: true, isLoginPage: false} // 로그인이 필요한 페이지
    },
    // 기타 라우트
];

const router = createRouter({
    history: createWebHistory(), // 웹 히스토리 모드 사용
    routes, // 라우트 설정
});

router.beforeEach(async (to, from, next) => {
    // 'requiresAuth' 메타 필드를 체크하여 로그인 필요 여부 결정
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // 여기서 세션 체크 로직 구현 (예: API 요청 또는 상태 관리)
        const response = await axios.get('/api/session-status');
        const check = response.data.loggedIn;
        if (!check) { // sessionUserExists는 세션 상태 확인 함수
            next('/login'); // 세션 정보가 없으면 로그인 페이지로 리디렉션
        } else {
            store.commit('setUsername', response.data.username)
            next(); // 세션 정보가 있으면 요청된 페이지로 이동
        }
    } else {
        next(); // 'requiresAuth'가 없는 페이지는 항상 허용
    }
});

// async function sessionUserExists() {
//     try {
//         const response = await axios.get('http://localhost:3000/session-status');
        
//         return response.data.loggedIn;
//     } catch (err) {
//         console.error('세션 상태 확인 중 오류 발생', err);
//         return false;
//     }
// }

export default router;