<template>
  <div id="sidenav-collapse-main" class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100">
    <ul class="navbar-nav">
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse :collapse-ref="username" :nav-text="username" :class="getRoute() === username ? 'active' : ''">
          <template #icon>
            <img src="../../assets/img/sample_user2.png" class="avatar" />
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->

              <!-- <sidenav-item :to="{ name: 'Settings' }" mini-icon="S" text="Settings" /> -->
              <sidenav-item :to="`/#`" @click="logout" mini-icon="logout" text="Logout" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <hr class="horizontal light mt-2 mb-2" />
      <li class="nav-item">
        <router-link class="nav-link" :to="`/wms-store`" :class="getRoute() === 'wms-store' ? 'active' : ''">
          <i class="material-symbols-outlined text-white">store</i>
          <span class="sidenav-normal me-3 ms-3 ps-1 text-white text-white">
            Receiving Part
          </span>
        </router-link>

      </li>
      <!-- <sidenav-item refer="receivingParts" :to="`/wms-store`" :class="getRoute() === 'wms-store' ? 'active' : ''"
        mini-icon="store" text="Receiving Part" /> -->

      <li class="mt-3 nav-item">
        <h6 class="text-xs ps-4 text-uppercase font-weight-bolder text-white">
          APPLICATION
        </h6>
      </li>
      <li class="nav-item active">
        <!--target="_blank"-->
        <sidenav-collapse collapse-ref="partDashboard" nav-text="Warehouse">
          <template #icon>
            <i class="material-symbols-outlined opacity-10">warehouse</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item refer="mainTransaction" :to="`/partDashboard`" mini-icon="align_items_stretch"
                text="Part Dashboard" :class="getRoute() === 'partDashboard' ? 'active' : ''" />
              <sidenav-item refer="transactionList" :to="`/warehouseDashboard`" mini-icon="inventory_2"
                text="Warehouse Dashboard" :class="getRoute() === 'warehouseDashboard' ? 'active' : ''" />

            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item active">
        <!--target="_blank"-->
        <sidenav-collapse collapse-ref="transaction" nav-text="Transaction">
          <template #icon>
            <i class="material-symbols-outlined opacity-10">local_shipping</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-if="username =='admin'" refer="mainTransaction" :to="`/mainTransaction`" mini-icon="request_quote"
                text="Transaction Request" :class="getRoute() === 'mainTransaction' ? 'active' : ''" />
              <sidenav-item refer="transactionList" :to="`/transactionRequest`" mini-icon="data_check"
                text="Transaction List" :class="getRoute() === 'transactionRequest' ? 'active' : ''" />

            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <!-- <li class="nav-item active">
        <a class="nav-link" href="/wms-store" :class="getRoute() === 'wms-store' ? 'active' : ''">

          <span class="material-symbols-outlined opacity-10">warehouse</span>
          <span>&nbsp; Store System</span>


        </a>
      </li>

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="ecommerceExamples" nav-text="Ecommerce"
          :class="getRoute() === 'ecommerce' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">shopping_basket</i>
          </template>

        </sidenav-collapse>
      </li> -->



    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import { mapState } from "vuex";
export default {
  name: "SidenavList",

  components: {
    SidenavItem,
    SidenavCollapse,
    // SidenavCollapseItem
  },
  data() {
    return {
      title: "Soft UI Dashboard",
      controls: "dashboards",
      isActive: "active"
    };
  },
  computed: {
    ...mapState({
      username: state => state.username
    })
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      console.log(routeArr);
      return routeArr[1];
    },
    async logout() {
            // 로그아웃 로직
            await fetch('/api/logout', {
                method: 'POST',
                credentials: 'include', // 쿠키를 포함하기 위해 필요
            });
            // 로그아웃 후 로그인 페이지로 리다이렉션
            this.$router.push('/login');
        }
  }
};
</script>
<style scoped>
#sidenav-collapse-main {
  font-family: 'Noto Sans KR' !important;
}</style>