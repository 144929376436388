<template lang="">
    <div v-if="loading" class="loading-overlay">
        <div class="spinner"></div>
        Loading...
    </div>
    <div class="container-fluid custom-font py-5">
        <div class="box top-box mb-3 p-4 rounded-3 bg-white d-flex align-items-center shadow">
            <h4 class="font-weight-bold">Transaction Master</h4>
        </div>
        
        
        <!-- <h4 class="mb-3 ml-5 mt-3">TRANSACTION REQUEST</h4>
            <v-divider></v-divider> -->
        <div class="justify-content-between d-flex">
            <div class="d-flex">
            
                <div class="mr-3 custom-date-box shadow">
                    <!--@update:model-value="fetchDataByDate"-->
                    <vue-datepicker v-model="date" range placeholder="Start Typing ..."
                        :text-input="textInputOptions" :is-24="false" :enable-time-picker="false"></vue-datepicker>
                    
                </div>
                <div class="d-flex mr-3">
                    <p class="black font-weight-bold mt-3 mr-3">STATUS</p>
                    <select class="custom-invo-input form-select mt-2 shadow" v-model="selectedStatus" title="Status">
                        <!-- 출고지 옵션을 동적으로 렌더링 -->
                        <option  v-for="(status, index) in statusOptions" :key="index" :value="status.value">{{ status.name }}</option>
                    </select>
                </div>
                <v-btn prepend-icon="mdi-magnify" class="mt-2 mr-3" @click="fetchDataByFilter">Inquiry</v-btn>
                
            </div>
            <v-btn prepend-icon="mdi-file-excel" elevated class="mt-2 bg-green" @click="exportToExcel">Export</v-btn>
        </div>
        
        <v-card class="mt-1">
        
            <v-tabs v-model="tab" color="deep-purple-accent-4">
                <v-tab value="1">Request Table</v-tab>
                <!-- <v-tab value="2">Shipping</v-tab> -->
                <!-- <v-tab value="9">Completed</v-tab> -->
            </v-tabs>
            
    
            <v-card-text>
                <v-window v-model="tab">
                    <v-window-item value="1">
                        <v-text-field
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            single-line
                            variant="outlined"
                            hide-details
                        >   
                        </v-text-field>
                        <v-data-table
                            :custom-filter="filterOnlyCapsText"
                            :headers="headers"
                            v-model:expanded="expanded"
                            :items="items"
                            :search="search"
                            item-value="name"
                            show-expand
                            class="text-h6"
                            >
                            <!-- <template v-slot:top>
                                
                            </template> -->
<template #[`item`]="{ item }">
                                <tr class="data-table-row" @click="fetchItem(item.OFFSET_NO)">
                                    <td class="custom-class text-start">{{ item.OFFSET_NO }}</td>
                                    <td class="text-end">{{ item.ESTIMATE_DATE }}</td>
                                    <td class="text-end">{{ item.ESTIMATE_TIME }}</td>
                                    <td class="text-end">{{ this.custCd[item.DEST] }}</td>
                                    <td class="text-end">
                                    <v-chip
                                        v-if="item.OFFSET_STATUS==='1'"
                                        color="red"
                                        class="text-uppercase"
                                        variant="flat"
                                        label
                                        size="small"
                                    >
                                        Not Completed
                                    </v-chip>
                                    <v-chip
                                        v-if="item.OFFSET_STATUS==='2'"
                                        color="orange"
                                        class="text-uppercase text-white"
                                        variant="flat"
                                        label
                                        size="small"
                                    >
                                        Shipping
                                    </v-chip>
                                    <v-chip
                                        v-if="item.OFFSET_STATUS==='9'"
                                        color="green"
                                        class="text-uppercase"
                                        variant="flat"
                                        label
                                        size="small"
                                    >
                                        Completed
                                    </v-chip>
                                    </td>
                                    <td v-if="this.username === 'admin'">
                                        <v-menu>
                                            <template #[`activator`]="{ props }">
                                                <v-icon class="custom-dots" icon="mdi-dots-horizontal" v-bind="props"></v-icon>
                                            </template>
<v-list>
    <v-list-item density="compact" class="custom-list-item">
        <button v-if="this.username === 'admin'" @click.stop="confirmDelete(item)" class="btn btn-sm">
            <v-icon size="24">mdi-delete</v-icon> 삭제
        </button>
    </v-list-item>
    <v-list-item density="compact" class="custom-list-item" v-if="item.OFFSET_STATUS == '1'">
        <button v-if="this.username === '111122'" @click.stop="confirmShipping(item)" class="btn btn-sm">
            <v-icon size="24">mdi-truck-cargo-container</v-icon> SHIPPING
        </button>
    </v-list-item>
</v-list>
</v-menu>
</td>

</tr>

</template>

</v-data-table>
<!-- 삭제 확인 모달 -->
<v-dialog v-model="dialog" max-width="500">
    <v-card>
        <v-card-title class="headline">삭제 확인</v-card-title>
        <v-card-text>
            정말 {{ selectedItem?.OFFSET_NO }}를 삭제하시겠습니까?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">아니요</v-btn>
            <v-btn color="red darken-1" text @click="deleteItem">예</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<!-- 삭제 확인 모달 -->
<v-dialog v-model="submitDialog" max-width="500">
    <v-card>
        <v-card-title class="headline">업데이트 확인</v-card-title>
        <v-card-text>
            정말 {{ selectedItem?.OFFSET_NO }}를 업데이트 하시겠습니까?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="submitDialog = false">아니요</v-btn>
            <v-btn color="red darken-1" text @click="submitShipping">예</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</v-window-item>

</v-window>
</v-card-text>
</v-card>
<ShowReqPartModal v-if="isModalVisible" :offsetNo="offsetNo" :products="fetchedData"
    @close-modal="isModalVisible = false" />
</div>
</template>
<script>
import axios from 'axios';
import VueDatepicker from '@vuepic/vue-datepicker';
import ShowReqPartModal from '@/components/Ui/ShowReqPartModal.vue';
import { mapState } from 'vuex';
import '@vuepic/vue-datepicker/dist/main.css';
import * as ExcelJs from 'exceljs';
import { saveAs } from 'file-saver';

export default {
    components: {
        VueDatepicker,
        ShowReqPartModal
    },
    data() {
        return {
            expanded: [],
            fetchedData: [],
            isModalVisible: false,
            loading: false,
            search: '',
            dateRange: '', // 선택된 날짜 범위
            date: null,
            selectedStatus: null, // MGA 또는 MAL 값을 저장하기 위한 데이터 속성
            sortedItems: [],
            isFill: false,
            tab: null,
            items: [],
            originItems: [],
            partsList: [],
            offsetNo: '',
            dialog: false,
            submitDialog: false,
            selectedItem: null,// 삭제할 항목을 저장
            statusOptions: [
                {
                    name: 'All',
                    value: '0'
                },
                {
                    name: 'Not Completed',
                    value: '1'
                },
                {
                    name: 'Shipping',
                    value: '2'
                },
                {
                    name: 'Completed',
                    value: '9'
                },
            ],
            shippingOptions: {
                '0': 'Not Shipped',
                '1': 'Shipping'
            },
            custCd: {
                'P0300': 'CNJ',
                'P0200': 'Shinhwa',
                'P0806': 'LOGIS ALL',
                'P0807': 'Leehwa',
                'P1903': 'SAU Plant 1',
                'P0250': 'SAU Plant 2'
            },

            moreList: [
                {
                    title: "Scan",
                    url: ""
                },
                {
                    title: "Pdf"
                }
            ],
            textInputOptions: {
                format: 'MM/dd/yyyy'
            },
            headers: [
                {
                    title: 'Ord No',
                    align: 'start',
                    key: 'OFFSET_NO',
                },
                {
                    title: 'Estimate Date',
                    align: 'end',
                    key: 'ESTIMATE_DATE',
                },
                {
                    title: 'Estimate Time',
                    align: 'end',
                    key: 'ESTIMATE_TIME',
                },
                {
                    title: 'Destination',
                    align: 'end',
                    key: 'DEST',
                },
                {
                    title: 'Status',
                    align: 'end',
                    key: 'OFFSET_STATUS',
                },
                {
                    title: '',
                    key: 'data-table-expand'
                },

            ]
        };
    },
    async mounted() {
        await this.getWeekStartAndEndDates();
        console.log(this.username);
        this.selectedStatus = '1'
        await this.fetchDataByFilter();
    },
    computed: {
        ...mapState({
            username: state => state.username
        }),
    },
    methods: {
        confirmDelete(item) {
            this.selectedItem = item;
            this.dialog = true;
        },
        confirmShipping(item) {
            this.selectedItem = item;
            this.submitDialog = true;
        },
        async submitShipping() {
            if (this.selectedItem) {
                try {
                    const response = await axios.get('/api/updateShippingStatus', {
                        params: {
                            offsetNo: this.selectedItem.OFFSET_NO
                        }
                    })
                    // let finalCheck = true;
                    // if (response) {
                    //     const res1 = await axios.post('/api/updateOffsetHeaderShipping', {
                    //         offsetNo: this.selectedItem.OFFSET_NO
                    //     })
                    //     if (res1.data.success) {
                            
                    //         for (const data of response.data) {
                    //             const res2 = await axios.post('/api/updateShippingStatus', {
                    //                 data: data
                    //             })
                    //             if (!res2.data.success) {
                    //                 finalCheck = false;
                    //             }
                    //         }

                    //     } else {
                    //         finalCheck = false;
                    //     }
                    // }

                    if (response.data.success) {
                        this.selectedItem.OFFSET_STATUS = '2';
                        alert("성공적으로 업데이트 했습니다.");
                    } else {
                        alert("업데이트에 실패했습니다.");
                    }
                    this.submitDialog = false;
                } catch (err) {
                    console.error('Error updating status: ', err);
                    alert("업데이트중 에러가 발생하였습니다.: ", err);
                    this.submitDialog = false;
                }
            }
        },
        async deleteItem() {
            if (this.selectedItem) {
                try {
                    console.log(this.selectedItem);
                    const response = axios.delete(`/api/deleteOffsetNo/${this.selectedItem.OFFSET_NO}`);
                    if (response) {
                        this.removeProduct(this.selectedItem);

                        alert("성공적으로 삭제 되었습니다.")
                    } else {
                        alert("삭제에 실패했습니다.");
                    }
                    this.dialog = false;
                } catch (err) {
                    console.error('Error deleting item: ', err);
                    alert('Error deleting item: ', err);
                }

            }
        },
        removeProduct(product) {
            // addedProducts에서 해당 제품을 제거합니다.
            const index = this.items.findIndex(p => p.OFFSET_NO === product.OFFSET_NO);

            if (index > -1) {
                this.items.splice(index, 1);
            }

        },
        async getWeekStartAndEndDates() {
            const newDate = new Date();
            // 주어진 날짜의 요일 (0: 일요일, 1: 월요일, ..., 6: 토요일)
            const dayOfWeek = newDate.getDay();

            // 주 시작일 (월요일)
            const startDate = new Date(newDate);
            startDate.setDate(newDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));

            // 주 종료일 (일요일)
            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + 6);

            // // 년월일 포맷으로 변환
            // const startDay = startDate.getFullYear() +
            //     String(startDate.getMonth() + 1).padStart(2, '0') +
            //     String(startDate.getDate()).padStart(2, '0');

            // const endDay = endDate.getFullYear() +
            //     String(endDate.getMonth() + 1).padStart(2, '0') +
            //     String(endDate.getDate()).padStart(2, '0');

            this.date = [startDate, endDate];
        },
        async fetchDataByFilter() {
            this.loading = true;
            // const startYear = this.date[0].getFullYear();
            // const startMm = String(this.date[0].getMonth()+1).padStart(2, '0');
            // const startDd = String(this.date[0].getDate()).padStart(2, '0');
            try {
                const response = await axios.get('/api/getReqTransaction');

                this.items = response.data;
                let tempList = [];
                for (let ord of response.data) {

                    if (!Object.prototype.hasOwnProperty.call(tempList, ord.OFFSET_NO)) {
                        tempList.push({
                            OFFSET_NO: ord.OFFSET_NO,
                            CONT_NO: [
                                ord.CONT_NO
                            ],
                            DEST: ord.DEST,
                            ESTIMATE_DATE: ord.ESTIMATE_DATE.substring(0, 8),
                            ESTIMATE_TIME: ord.ESTIMATE_DATE.substring(8, 13),
                            REQUEST_DATE: ord.REQUEST_DATE,
                            OFFSET_STATUS: ord.OFFSET_STATUS
                        })
                    } else {
                        tempList[ord.OFFSET_NO].CONT_NO.push(ord.CONT_NO);
                    }

                }
                console.log(tempList);
                this.items = tempList;
                this.originItems = this.items;
                if (this.date != null) {
                    const startDay = this.date[0].getFullYear() + String(this.date[0].getMonth() + 1).padStart(2, '0')
                        + String(this.date[0].getDate()).padStart(2, '0');
                    const endDay = this.date[1].getFullYear() + String(this.date[1].getMonth() + 1).padStart(2, '0')
                        + String(this.date[1].getDate()).padStart(2, '0');
                    console.log(startDay);
                    if (this.selectedStatus != '' && this.selectedStatus != '0') {
                        this.items.forEach(item => {
                            if (item.ESTIMATE_DATE >= startDay && item.ESTIMATE_DATE <= endDay) {
                                if (item.OFFSET_STATUS == this.selectedStatus) {
                                    this.sortedItems.push(item);
                                }
                            }
                        });
                    } else {
                        this.items.forEach(item => {
                            if (item.ESTIMATE_DATE >= startDay && item.ESTIMATE_DATE <= endDay) {
                                this.sortedItems.push(item);
                            }
                        });
                    }

                    this.items = this.sortedItems;
                    this.sortedItems = [];
                    console.log(this.items);
                } else {

                    if (this.selectedStatus != '' && this.selectedStatus != '0') {
                        console.log(this.selectedStatus)
                        this.items.forEach(item => {
                            if (item.OFFSET_STATUS == this.selectedStatus) {
                                this.sortedItems.push(item);
                            }
                        })
                        this.items = this.sortedItems;
                        this.sortedItems = [];
                    } else {
                        this.items = this.originItems;
                    }


                }
            } catch (err) {
                console.error('Data fetch error: ', err);
            }



            // this.sortedItems 
            this.loading = false;
        },

        // 입고처리할 데이터 가져오는 메서드
        async fetchData() {
            this.loading = true;
            try {
                const response = await axios.get('/api/getReqTransaction');

                this.items = response.data;
                let tempList = [];
                for (let ord of response.data) {

                    if (!Object.prototype.hasOwnProperty.call(tempList, ord.OFFSET_NO)) {
                        tempList.push({
                            OFFSET_NO: ord.OFFSET_NO,
                            CONT_NO: [
                                ord.CONT_NO
                            ],
                            DEST: ord.DEST,
                            ESTIMATE_DATE: ord.ESTIMATE_DATE.substring(0, 8),
                            ESTIMATE_TIME: ord.ESTIMATE_DATE.substring(8, 13),
                            REQUEST_DATE: ord.REQUEST_DATE,
                            OFFSET_STATUS: ord.OFFSET_STATUS
                        })
                    } else {
                        tempList[ord.OFFSET_NO].CONT_NO.push(ord.CONT_NO);
                    }

                }
                console.log(tempList);
                this.items = tempList;
                this.originItems = this.items;
            } catch (err) {
                console.error('Data fetch error: ', err);
            }
            this.loading = false;
        },

        // 출고처리할 아이템 데이터 가져오는 메서드
        async fetchItem(offsetNo) {
            this.loading = true;
            try {
                const response = await axios.get('/api/getReqDistinctItemByCont', {
                    params: {
                        offsetNo: offsetNo
                    }
                });
                this.fetchedData = response.data;
                this.offsetNo = offsetNo;

                this.isModalVisible = true;
            } catch (err) {
                console.error('Part fetch error: ', err);
                this.isModalVisible = false;
            }
            this.loading = false;
        },
        async exportToExcel() {
            const exportList = [];
            for (const item of this.items) {
                try {
                    const response = await axios.get('/api/getReqDistinctItemByCont', {
                        params: {
                            offsetNo: item.OFFSET_NO
                        }
                    });
                    console.log(response.data);
                    for (let detailItem of response.data) {
                        detailItem.ITMNO.forEach((itmno, index) => {
                            if (this.isNumericChar(itmno.charAt(0))) {
                                itmno = itmno.substring(0, 5) + '-' + itmno.substring(5);
                            } else {
                                itmno = itmno.substring(0, 6) + '-' + itmno.substring(6);
                            }
                            exportList.push({
                                OFFSET_NO: item.OFFSET_NO,
                                CONT_NO: detailItem.CONT_NO,
                                ITMNO: itmno,
                                QTY: detailItem.QTY[index],
                                ESTIMATE_DATE: item.ESTIMATE_DATE,
                                ESTIMATE_TIME: item.ESTIMATE_TIME,
                                DEST: this.custCd[item.DEST],
                                TYPE: detailItem.BOX_ADD,
                                SCAN_STATUS: this.shippingOptions[detailItem.SCAN_STATUS]
                            })
                        })
                    }

                } catch (err) {
                    console.error('Export error: ', err);
                }
            }
            console.log(exportList);
            const workbook = new ExcelJs.Workbook();
            const worksheet = workbook.addWorksheet('Shipping Request');

            // // 헤더 추가
            const newHeaders = [
                {
                    title: 'Type',
                    key: 'TYPE',
                },
                {
                    title: 'Ord No',
                    key: 'OFFSET_NO',
                },
                {
                    title: 'Cont No',
                    key: 'CONT_NO',
                },
                {
                    title: 'Estimate Date',
                    key: 'ESTIMATE_DATE',
                },
                {
                    title: 'Estimate Time',
                    key: 'ESTIMATE_TIME',
                },
                {
                    title: 'Destination',
                    key: 'DEST',
                },
                {
                    title: 'Status',
                    key: 'SCAN_STATUS',
                },
                {
                    title: 'Item No',
                    key: 'ITMNO',
                },
                {
                    title: 'Qty',
                    key: 'QTY',
                },

            ]
            worksheet.columns = newHeaders.map(col => ({
                header: col.title,
                key: col.key,
            }))

            // // 데이터 추가
            exportList.forEach(item => {
                worksheet.addRow(item);
            });

            // // 엑셀 파일 생성
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            saveAs(blob, 'exporedFile.xlsx');
        },
        isNumericChar(char) {
            return !isNaN(parseFloat(char)) && isFinite(char);
        }

    }
}
</script>
<style scoped>
.selected {
    background-color: #0dcaf0;
    color: white;
    border-color: #0dcaf0;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
}

.custom-class {
    font-weight: bold;

    /* 여기에 추가적인 스타일을 정의합니다 */
}

.custom-dots {
    transition: all 0.2s ease-out;
}

.custom-dots:hover,
.custom-dots:active {
    color: #e91e63;
    cursor: pointer;

}


.data-table-row:hover {
    background-color: #f5f5f5;
    /* 마우스 오버 시 배경색 변경 */
    cursor: pointer;
}

.data-table-row td {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;

}

.data-table-row td {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;

}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid black;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.custom-list-item:hover {
    background-color: #f5f5f5;
}

.min-height {
    min-height: 647px;
}

.btn-outline-info {
    margin-left: 5px;
}

.sh-logo {
    transform: translateY(-2px);
    margin-right: 10px;
}

.custom-table {
    min-height: 450px;
}

.custom-input {
    border: 1px solid;
    background-color: white;
    padding-left: 5px;
}

.custom-button {
    margin-top: 15px;
}

.custom-input:focus {
    background-color: white !important;
    border: 2px solid;
    padding-left: 5px;
}

.custom-date-box {
    width: 300px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 15px;
}

.custom-invo-input {
    border: 1px solid #CFD0D2;
    width: 150px;
    height: 38px;
    margin-right: 15px;
    background-color: white;
    padding-left: 7px;

    margin-bottom: 15px;
    color: black;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
