<template>
    <div class="container-fluid py-5 custom-font">
        <div class="row">
            <div class="col-md-12 d-flex">
                <v-card class="w-75 custom-card mt-3 mr-3">
                    <div class="d-flex justify-content-between ml-3 mt-3">
                        <h5 class="">재고 현황</h5>
                        <v-btn variant="text" :to="`/partDashboard`" class="font-weight-bold mr-2">
                            <i class="material-symbols-outlined mr-1">add</i>
                            더보기
                        </v-btn>

                    </div>

                    <v-divider></v-divider>
                    <v-data-table :headers="headers" :items="partsList" item-value="name">

                    </v-data-table>
                </v-card>
                <v-card class="w-25 custom-card mt-3">


                    <!-- 탭 -->
                    <v-tabs v-model="tab" color="deep-purple-accent-4">
                        <v-tab value="1" class="font-weight-bold">내 현황</v-tab>
                    </v-tabs>
                    <v-card-text>
                        <v-window v-model="tab">
                            <v-window-item value="1">
                                <div class="d-flex">
                                    <p class="font-weight-bold mr-2">배송요청</p>
                                    <v-chip color="red" class="text-uppercase mr-3" label size="small">
                                        {{ requestStatus }} 건
                                    </v-chip>
                                    <p class="font-weight-bold mr-2">배송중</p>
                                    <v-chip color="orange" class="text-uppercase mr-3" label size="small">
                                        {{ shippingStatus }} 건
                                    </v-chip>
                                    <p class="font-weight-bold mr-2">배송완료</p>
                                    <v-chip color="green" class="text-uppercase" label size="small">
                                        {{ completeStatus }} 건
                                    </v-chip>
                                </div>
                                <channels-chart-card v-if="dataLoaded" :status="{
                                    labels: ['Order Request', 'In Transit', 'Delivered'],
                                    data: [requestStatus, shippingStatus, completeStatus]
                                }" />
                            </v-window-item>
                        </v-window>
                    </v-card-text>


                </v-card>




                <!-- <div class="jumbotron">
                    <h1 class="display-4">Welcome, {{ username }}!</h1>
                    <p class="lead">This is Offset management system.</p>
                    <hr class="my-4">
                    <p>It is prototype system for store parts to the Storage.
                    </p>
                    <p class="lead">
                        <button @click="logout" class="btn btn-primary btn-lg" role="button">Logout</button>
                    </p>
                </div> -->
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';
import ChannelsChartCard from "@/components/Charts/ChannelsChartCard.vue";
import setTooltip from "@/assets/js/tooltip.js";
export default {
    props: {
        username: String
    },
    components: {
        ChannelsChartCard
    },
    data() {
        return {
            tab: null,
            dataLoaded: false,
            loading: false,
            partsList: [],
            headers: [
                {
                    title: 'Ord No',
                    align: 'start',
                    key: 'ORD_NO'
                },
                {
                    title: 'Item No',
                    align: 'start',
                    key: 'ITMNO'
                },
                {
                    title: 'Item Name',
                    align: 'end',
                    key: 'ITM_NM'
                },
                {
                    title: 'Part Qty',
                    align: 'end',
                    key: 'BOX2_QTY'
                },
                {
                    title: 'Status',
                    align: 'end',
                    key: 'STATUS'
                }
            ],
            statusList: [],
            requestStatus: 0,
            shippingStatus: 0,
            completeStatus: 0,
        }
    },
    async mounted() {
        await this.fetchStatus();
        await this.fetch10Parts();
        setTooltip(this.$store.state.bootstrap);
    },
    methods: {
        async logout() {
            // 로그아웃 로직
            await fetch('/api/logout', {
                method: 'POST',
                credentials: 'include', // 쿠키를 포함하기 위해 필요
            });
            // 로그아웃 후 로그인 페이지로 리다이렉션
            this.$router.push('/login');
        },
        async fetch10Parts() {
            this.loading = true;
            try {
                const response = await axios.get('/api/getOffsetPartsRecent10');
                if (response.error) {
                    console.log(response.error);
                } else if (response.message) {
                    console.log(response.message);
                } else {
                    this.partsList = response.data;
                }
            } catch (err) {
                console.error("Fetch parts error: ", err);
            }
        },
        async fetchStatus() {
            this.loading = true;
            try {
                const response = await axios.get('/api/getTransactionStatusByCust', {
                    params: {
                        username: this.username
                    }
                });
                console.log(response);
                this.statusList = response.data;
                await this.countStatus();
            } catch (err) {
                console.error("Fetch status error: ", err);
            }
            this.loading = false;
        },
        async countStatus() {
            this.loading = true;
            for (const item of this.statusList) {
                if (item.OFFSET_STATUS == '1') {
                    this.requestStatus += 1;
                } else if (item.OFFSET_STATUS == '2') {
                    this.shippingStatus += 1;
                } else {
                    this.completeStatus += 1;
                }
            }
            this.dataLoaded = true;
            this.loading = false;
        }
    }
};
</script>
<style scoped>
.custom-font {
    font-family: 'Noto Sans KR' !important;
}
</style>